import React, { useState } from "react";
import { Table } from "antd";
import { Loader } from "shared/ui";
import { useTranslation } from "react-i18next";
import { Widget, WidgetTableData, WidgetTypeEnum } from "shared/api";
import { GenerateMultiplePackagingReportModal } from "pages/Orders/Orders/UI/GenerateMultiplePackagingReportModal";
import { useGetOrderIdToUpdate, useModal } from "shared/hooks";
import {
  ArticleFlowWidget,
  MachinesXWidget,
  MaterialConsumptionWidget,
  MaterialTargetAlert,
  OrderStatusWidget,
} from "./widgets";
import { ProductionDataTracker } from "./widgets/ProductionDataTracker";
import { ConfigurationsAlert } from "./ConfigurationsAlert";
import { StandAloneGraph } from "./widgets/StandAloneWidget/ui";

interface WidgetTableProps {
  widgetsData: WidgetTableData[];
  widget: Widget;
}

const WidgetTable: React.FC<WidgetTableProps> = ({ widgetsData, widget }) => {
  const { t } = useTranslation();
  const [orderIdToGenerateReport, setOrderIdToGenerateReport] = useState<
    number | null
  >(null);

  const [
    isVisibleGenerateMultiplePackagingReportModal,
    showGenerateMultiplePackagingReportModal,
    hideGenerateMultiplePackagingReportModal,
  ] = useModal();

  const handleGenerateReport = (orderId: number) => {
    setOrderIdToGenerateReport(orderId);
    showGenerateMultiplePackagingReportModal();
  };

  const { clearOrderId } = useGetOrderIdToUpdate();

  const widgetType = widgetsData?.[0]?.type;

  const showConfigurationsAlert =
    widget?.type === WidgetTypeEnum.PRODUCTION_DATA_TRACKER &&
    !widget?.configuration.length;

  const renderWidgetData = (
    text: string,
    record: WidgetTableData,
    key: string
  ) => {
    if (
      widgetType === WidgetTypeEnum.ITEM_FLOW ||
      widgetType === WidgetTypeEnum.QUALITY_TRACKING
    ) {
      return <ArticleFlowWidget text={text} record={record} fieldKey={key} />;
    }

    if (widgetType === WidgetTypeEnum.MATERIAL_CONSUMPTION) {
      return (
        <MaterialConsumptionWidget text={text} record={record} fieldKey={key} />
      );
    }

    if (widgetType === WidgetTypeEnum.ORDER_STATUS) {
      return (
        <OrderStatusWidget
          handleGenerateReport={handleGenerateReport}
          text={text}
          record={record}
          fieldKey={key}
        />
      );
    }

    if (widgetType === WidgetTypeEnum.MACHINES) {
      return <MachinesXWidget text={text} record={record} fieldKey={key} />;
    }

    if (widgetType === WidgetTypeEnum.MATERIAL_TARGET_ALERT) {
      return <MaterialTargetAlert text={text} record={record} fieldKey={key} />;
    }

    if (widgetType === WidgetTypeEnum.PRODUCTION_DATA_TRACKER) {
      return (
        <ProductionDataTracker text={text} record={record} fieldKey={key} />
      );
    }

    if (widgetType === WidgetTypeEnum.GRAPH_MATERIAL_LEVEL) {
      return (
        <ProductionDataTracker text={text} record={record} fieldKey={key} />
      );
    }

    return null;
  };

  if (!widgetsData) return <Loader />;

  if (showConfigurationsAlert) {
    return <ConfigurationsAlert />;
  }

  const widgetColumns = widget?.column_names ?? [];

  const columns = widgetsData?.[0]
    ? Object.keys(widgetsData?.[0])
        .filter((key) => widgetColumns.includes(key as any))
        .map((key) => ({
          title: t(`widgets.${key}`),
          dataIndex: key,
          key,
          render: (text: string, record: WidgetTableData) =>
            renderWidgetData(text, record, key),
        }))
    : [];

  if (widget.type === WidgetTypeEnum.ORDER_STATUS) {
    columns.push({
      title: t("widgets.actions"),
      dataIndex: "orderActions",
      key: "orderActions",
      render: (text: string, record: WidgetTableData) =>
        renderWidgetData(text, record, "orderActions"),
    });
  }

  if (widgetsData?.[0]?.type === WidgetTypeEnum.STANDALONE_LINE_GRAPH) {
    return <StandAloneGraph widgetsData={widgetsData} />;
  }

  return (
    <>
      <Table
        loading={!widgetsData}
        columns={columns}
        dataSource={widgetsData}
        className="w-full"
        pagination={false}
      />
      <GenerateMultiplePackagingReportModal
        isOpen={isVisibleGenerateMultiplePackagingReportModal}
        onClose={() => {
          hideGenerateMultiplePackagingReportModal();
          setOrderIdToGenerateReport(null);
        }}
        orderIdToGenerateReport={orderIdToGenerateReport}
        clearOrderId={clearOrderId}
      />
    </>
  );
};

const WidgetTableMemo = React.memo(WidgetTable);

export { WidgetTableMemo as WidgetTable };
