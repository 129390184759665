import { ItemType } from "antd/es/menu/hooks/useItems";
import { TFunction } from "i18next";
import { AdaptedValues } from "shared/types";
import { MaterialDetailsItem, MaterialsItem } from "shared/api";
import { DefaultOptionType } from "antd/es/select";

export type AnyType = any;

export const isDev = process.env.NODE_ENV === "development";

export const calculateOffset = (pageSize: number, currentPage: number) => {
  return pageSize * currentPage - pageSize;
};

export const handleRequestError = (error: AnyType, defaultMessage: string) => {
  const defaultErrorMessage = defaultMessage;

  if (error.originalStatus === 500) {
    return "Something goes wrong";
  }

  if (error.data) {
    const errorValues = Object.values(error.data);

    const firstError = Array.isArray(errorValues[0])
      ? errorValues[0][0]
      : errorValues[0];
    return firstError || defaultErrorMessage;
  }

  return defaultErrorMessage;
};

export const addThousandsSeparator = (
  num: number | null | undefined
): string => {
  if (!num) {
    return "0";
  }

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const renderUnit = (
  materialItem: MaterialsItem | null,
  item: MaterialDetailsItem,
  justUnit?: boolean
) => {
  const unit = materialItem?.material_category?.unit;
  if (typeof unit === "object" && !justUnit) {
    return `${addThousandsSeparator(item.quantity_available)} - ${
      unit.abbreviation
    }`;
  }

  if (typeof unit === "object" && justUnit) {
    return unit.abbreviation;
  }

  return "Unknown";
};

export const generateRandomId = (length: number): string => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  return Array.from(
    { length },
    () => characters[Math.floor(Math.random() * characters.length)]
  ).join("");
};

export const createRandomBackgroundColor = () => {
  const colors = [
    "!bg-orange-100",
    "!bg-purple-200",
    "!bg-green-300",
    "!bg-red-300",
    "!bg-red-400",
  ];
  const randomIndex = Math.floor(Math.random() * colors.length);
  const randomColor = colors[randomIndex];

  return randomColor || "!bg-blue-primary";
};

export const noop = () => {};

export const openHyperlinkInTab = (url: string) => {
  window.open(url, "_blank");
};

export const getInputMaterialIds = (string: string) => {
  return string.split(",").map(Number);
};

export const downloadJsonFile = (response: void, fileName: string) => {
  const blob = new Blob([JSON.stringify(response, null, 2)], {
    type: "application/json",
  });

  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");

  link.href = url;

  link.download = `${fileName}.json`;

  document.body.appendChild(link);

  link.click();
};

export const addDashboardKey = "addDashboard";

export const translateSidebarItems = (
  sidebarItems: ItemType[],
  t: TFunction<"translation", undefined, "translation">
) => {
  return sidebarItems.map((item) => {
    if (item && "label" in item && typeof item.label === "string") {
      let children;
      if ("children" in item) {
        children = item?.children?.map((child) => {
          if (child && "label" in child && typeof child.label === "string") {
            return {
              ...child,
              label: t(child.label),
            };
          }
          return child;
        });
      }
      return {
        ...item,
        label: t(item.label),
        children,
      };
    }
    return item;
  });
};

export const selectFiltering = (
  input: string,
  option?: AdaptedValues | DefaultOptionType
) => {
  return ((option?.label?.toString() || option?.value?.toString()) ?? "")
    .toLowerCase()
    .includes(input.toLowerCase());
};

export const reloadPage = () => {
  window.location.reload();
};

export const handleDownloadFile = (url: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "file");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
