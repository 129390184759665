import { Input, notification } from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  MaterialStockItem,
  useGetMaterialItemsByIDQuery,
  useUpdateStockMaterialItemMutation,
} from "shared/api";
import { FormInput, FormSelect, HyperlinkPageHeader } from "shared/ui";
import { FormikProvider, useFormik } from "formik";
import {
  getDynamicArticleData,
  handleRequestError,
  noop,
} from "shared/helpers";
import { AlertOutlined } from "@ant-design/icons";
import { adaptDynamicFormValues } from "shared/adapters";
import { useTranslation } from "react-i18next";
import { AddStockMaterialItemValues, DynamicValues } from "shared/types";
import { materialItemSituations, materialItemStatuses } from "shared/constants";
import { createAddOrUpdateMaterialItemSchema } from "./MaterialItems/schemas/schemas";
import { DynamicArticleHyperlinkData } from "./DynamicArticleHyperlinkData";

const ArticleHyperlink = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [api, contextHolder] = notification.useNotification();

  const { data: articles } = useGetMaterialItemsByIDQuery({
    id: Number(id),
    expand: "material_stock_batch.material.material_category",
  });

  const articleData = (
    articles as unknown as MaterialStockItem[]
  )?.[0] as MaterialStockItem;

  const [dynamicValues, setDynamicValues] = useState<DynamicValues[] | []>([]);

  useEffect(() => {
    const dynamicValues = getDynamicArticleData(articleData);

    setDynamicValues(dynamicValues);
  }, [articleData]);

  const dynamicFieldsValidation = adaptDynamicFormValues(dynamicValues);

  const [updateStockMaterialItem] = useUpdateStockMaterialItemMutation();

  const addOrUpdateStockMaterialItemFormik =
    useFormik<AddStockMaterialItemValues>({
      initialValues: {
        id: articleData?.id || null,
        status: articleData?.status || null,
        material_stock_batch: articleData?.material_stock_batch?.id || null,
        serial_number: articleData?.serial_number || "",
        quantity: articleData?.quantity ?? 0,
        dynamic_tracker_fields: articleData?.dynamic_tracker_fields || {},
        situation: articleData?.situation || null,
      },
      validateOnMount: true,
      validateOnBlur: true,
      validationSchema: createAddOrUpdateMaterialItemSchema(
        dynamicFieldsValidation
      ),
      enableReinitialize: true,
      onSubmit: noop,
    });

  const { values, handleBlur, handleChange, setFieldValue, errors, isValid } =
    addOrUpdateStockMaterialItemFormik;

  const emptyQuantityField = values.quantity === null || values.quantity === "";

  const updateStockMaterialItemRequest = async () => {
    try {
      await updateStockMaterialItem({
        id: values.id!,
        dynamic_tracker_fields: values.dynamic_tracker_fields,
        material_stock_batch: Number(values?.material_stock_batch),
        quantity: (values.quantity as number) ?? 0,
        serial_number: values.serial_number,
        status: values.status!,
        situation: values.situation!,
      }).unwrap();
      api.success({
        message: t("stock-material-item.success-title"),
        description: t("stock-material-item.success-update-msg"),
      });
    } catch (error) {
      api.error({
        message: t("stock-material-item.error"),
        description: handleRequestError(error, t("stock-material-item.error")),
      });
    }
  };

  return (
    <>
      {contextHolder}
      <FormikProvider value={addOrUpdateStockMaterialItemFormik}>
        <div className="h-auto w-screen flex items-center justify-center flex-col ">
          <HyperlinkPageHeader
            title="Article Hyperlink"
            isValid={isValid}
            onOk={updateStockMaterialItemRequest}
          />

          <div className="w-full p-4">
            <Title level={5}>
              {t("material-management.material-stock-batch")}
            </Title>

            <Input
              value={articleData?.material_stock_batch?.material?.name}
              size="large"
              disabled
            />

            <Title level={5}>{t("material-management.serial-number")}</Title>

            <div className="relative">
              <FormInput
                value={values.serial_number}
                fieldName="serial_number"
                placeholder={t("material-management.serial-num-placeholder")}
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.serial_number?.toString()}
                isError={!!errors.serial_number}
              />
              {!values.serial_number.length && (
                <AlertOutlined className="btn-remove  absolute top-2 right-2" />
              )}
            </div>

            <Title level={5}>{t("material-management.quantity")}</Title>

            <div className="relative">
              <FormInput
                value={values.quantity}
                fieldName="quantity"
                placeholder={t("material-management.quantity-placeholder")}
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.quantity?.toString()}
                isError={!!errors.quantity}
                numberType
              />
              {emptyQuantityField && (
                <AlertOutlined className="btn-remove  absolute top-2 right-2" />
              )}

              <Title level={5}>{t("material-management.status")}</Title>

              <FormSelect
                value={values.status}
                placeholder={t("material-management.status-placeholder")}
                fieldName="status"
                options={materialItemStatuses}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                error={errors.status?.toString()}
                isError={!!errors.status}
              />
            </div>

            <Title level={5}>{t("stock-material-item.situation")}</Title>

            <FormSelect
              value={values.situation}
              placeholder={t("stock-material-item.situation-placeholder")}
              fieldName="situation"
              options={materialItemSituations}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
            />

            <DynamicArticleHyperlinkData dynamicValues={dynamicValues} />
          </div>
        </div>
      </FormikProvider>
    </>
  );
};

export { ArticleHyperlink };
