import React from "react";
import { BrowserRouter, Routes as RoutesDom, Route } from "react-router-dom";
import { pagesConfig } from "pages";
import { ProcessStepsHyperlink } from "pages/ProductCards/ProcessSteps";
import { ProtectedRoute } from "./ProtectedRoute";
import { Routes } from "./Routes";
import { RouteItem } from "./models";

export const Router: React.FC = () => {
  const {
    Quality,
    TemplatesConfig,
    Dashboards,
    ArticleHyperlink,
    MachinesHyperlink,
    OrderHyperlink,
    ProcessTemplateHyperlink,
    ProductCardHyperlink,
    MaterialHyperlink,
    MaterialCategoryHyperlink,
    SignIn,
    MaterialCategoryManagement,
    Users,
    ProcessTemplates,
    Machines,
    ProductCards,
    ProcessSteps,
    Companies,
    Orders,
    ProcessStepProduction,
    DynamicProcessStepsForTheOrderPage,
    OrderPlans,
    TimelinePage,
    OrderReports,
    ExecuteReport,
    CompaniesHyperlink,
    UpdatedMaterialManagement,
    ItemView,
    StandAlone,
  } = pagesConfig;

  const routesConfig: RouteItem[] = [
    { path: Routes.signIn.url, component: SignIn, isProtected: false },

    {
      path: Routes.users.url,
      component: Users,
      isProtected: true,
      props: { isOpenSidebar: true },
    },

    {
      path: Routes.companies.url,
      component: Companies,
      isProtected: true,
    },
    {
      path: Routes.companies.hyperlink,
      component: CompaniesHyperlink,
      isProtected: true,
    },

    {
      path: Routes.materialManagement.url,
      component: UpdatedMaterialManagement,
      isProtected: true,
    },

    {
      path: Routes.materialManagement.hyperlink,
      component: MaterialHyperlink,
      isProtected: true,
    },

    {
      path: Routes.materialManagement.articleUrl,
      component: ArticleHyperlink,
      isProtected: true,
    },

    {
      path: Routes.materialCategoryManagement.url,
      component: MaterialCategoryManagement,
      isProtected: true,
    },
    {
      path: Routes.processTemplates.url,
      component: ProcessTemplates,
      isProtected: true,
    },

    {
      path: Routes.processTemplates.hyperlink,
      component: ProcessTemplateHyperlink,
      isProtected: true,
    },

    {
      path: Routes.machines.url,
      component: Machines,
      isProtected: true,
    },

    {
      path: Routes.machines.hyperlinkUrl,
      component: MachinesHyperlink,
      isProtected: true,
    },

    {
      path: Routes.productCards.url,
      component: ProductCards,
      isProtected: true,
    },

    {
      path: Routes.productCards.hyperlink,
      component: ProductCardHyperlink,
      isProtected: true,
    },

    {
      path: Routes.processSteps.url,
      component: ProcessSteps,
      isProtected: true,
    },

    {
      path: Routes.orders.url,
      component: Orders,
      isProtected: true,
    },

    {
      path: Routes.orders.hyperlinkUrl,
      component: OrderHyperlink,
      isProtected: true,
    },

    {
      path: Routes.processStepProduction.url,
      component: ProcessStepProduction,
      isProtected: true,
    },
    {
      path: Routes.processStepProduction.nestedUrl,
      component: DynamicProcessStepsForTheOrderPage,
      isProtected: true,
    },

    {
      path: Routes.orderPlans.url,
      component: OrderPlans,
      isProtected: true,
    },
    {
      path: Routes.orderPlans.nestedUrl,
      component: TimelinePage,
      isProtected: true,
    },
    {
      path: Routes.quality.url,
      component: Quality,
      isProtected: true,
    },
    {
      path: Routes.templateConfig.url,
      component: TemplatesConfig,
      isProtected: true,
    },
    {
      path: Routes.dashboards.url,
      component: Dashboards,
      isProtected: true,
    },
    {
      path: Routes.materialCategoryManagement.hyperlink,
      component: MaterialCategoryHyperlink,
      isProtected: true,
    },
    {
      path: Routes.orders.reportsUrl,
      component: OrderReports,
      isProtected: true,
    },
    {
      path: Routes.executeReport.url,
      component: ExecuteReport,
      isProtected: true,
    },
    {
      path: Routes.processSteps.hyperlink,
      component: ProcessStepsHyperlink,
      isProtected: true,
    },
    {
      path: Routes.itemView.url,
      component: ItemView,
      isProtected: true,
    },
    {
      path: Routes.standAlone.url,
      component: StandAlone,
      isProtected: true,
    },
  ];

  const renderRoute = (route: RouteItem) => {
    const Component = route.component;

    const routeProps = route.props || {};

    return route.isProtected ? (
      <ProtectedRoute>
        <Component {...routeProps} />
      </ProtectedRoute>
    ) : (
      <Component {...routeProps} />
    );
  };

  return (
    <BrowserRouter>
      <RoutesDom>
        {routesConfig.map((route, index) => (
          <Route key={index} path={route.path} element={renderRoute(route)} />
        ))}
      </RoutesDom>
    </BrowserRouter>
  );
};
