import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { ThemeProvider } from "./shared/hooks/useThemeProvider";
import { Router } from "./shared/routers";
import { store, persistor } from "./shared/stores";
import { LocalizationService } from "./shared/services";
import "./index.css";

LocalizationService.initLocalization();

const root = ReactDOM.createRoot(document.getElementById("root"));

const companyName = localStorage.getItem("companyName");
const tabTitle = companyName ? `Chainlinx - ${companyName}` : "Chainlinx";

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HelmetProvider>
          <Helmet htmlAttributes={{ lang: "en" }}>
            <title>{tabTitle}</title>
            <meta
              name="viewport"
              content="initial-scale=1, width=device-width"
            />
          </Helmet>
          <ThemeProvider>
            <DndProvider backend={HTML5Backend}>
              <Router />
            </DndProvider>
          </ThemeProvider>
        </HelmetProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
