import Title from "antd/es/typography/Title";
import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Collapse } from "antd";
import { transformObject, updateDynamicProcessStep } from "shared/adapters";
import { DynamicOrderObject, OrderStepTrackerResponseItem } from "shared/api";
import { FieldType } from "shared/types";
import { ConfigCardStepFields } from "../ConfigCardStepFields";
import { ProductionGuiNBC } from "./ProductionGuiNBC";

interface DynamicStepsContainerProps {
  orderStepTrackerResponseItem: OrderStepTrackerResponseItem | undefined;
}

const DynamicStepsContainer: FC<DynamicStepsContainerProps> = ({
  orderStepTrackerResponseItem,
}) => {
  const { t } = useTranslation();

  const dynamicProcessStep =
    orderStepTrackerResponseItem?.process_step.process_template
      .config_dynamic_process_step_fields;

  const processStep =
    orderStepTrackerResponseItem?.process_step?.dynamic_process_step_fields;

  const transformedProcessStep = useMemo(() => {
    return transformObject(processStep as DynamicOrderObject);
  }, [processStep]);

  const updatedDynamicProcessStep = useMemo(
    () =>
      updateDynamicProcessStep(
        dynamicProcessStep,
        transformedProcessStep,
        (field: FieldType, value: string | number) => ({
          ...field,
          value,
        })
      ),
    [dynamicProcessStep, transformedProcessStep]
  );

  const [activeKeys, setActiveKeys] = useState<string[]>([]);

  const handlePanelChange = (keys: string | string[]) => {
    if (typeof keys === "string") {
      keys = [keys];
    }
    setActiveKeys(keys);
  };

  return (
    <div
      className="
        w-full h-[100%] overflow-auto 
        shadow-dynamicFormCard rounded-xl
      "
    >
      <div className="bg-blue-primary e h-[48px]">
        <Title level={3} className="mb-1.25 pl-2 !text-white pt-2">
          {t("orders.process-information")}
        </Title>
      </div>

      {updatedDynamicProcessStep
        ?.filter((card: any) => card.title !== "Misc")
        .map((card: any, cardIndex: number) => {
          const cardKey = `${card.title}_${cardIndex}`;
          const isOrderCard = card.title.includes("Order");

          return (
            <Collapse
              accordion={false}
              key={cardKey}
              className="!white-arrow p-2"
              activeKey={isOrderCard ? [cardKey, ...activeKeys] : activeKeys}
              onChange={handlePanelChange}
            >
              <Collapse.Panel
                header={<span className="!text-white">{card.title}</span>}
                key={cardKey}
                className={`w-full bg-blue-primary !text-white pl-3 py-2 ${
                  isOrderCard ? "always-open" : ""
                }`}
              >
                {isOrderCard ? (
                  <ProductionGuiNBC
                    orderStepTrackerResponseItem={orderStepTrackerResponseItem}
                  />
                ) : (
                  <ConfigCardStepFields card={card} key={card.title} />
                )}
              </Collapse.Panel>
            </Collapse>
          );
        })}
    </div>
  );
};

export { DynamicStepsContainer };
