import React, { Dispatch, FC, SetStateAction } from "react";
import { Alert, Button, notification } from "antd";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  InputMaterialFormik,
  OrderStepTrackerResponseItem,
  ProductionOutputMaterial,
  useUpdateOrderProcessStepTrackersProduceMaterialItemsMutation,
} from "shared/api";
import { adaptInputMaterialValues, handleRequestError } from "shared/helpers";
import { adaptOutputMaterialsValues } from "shared/adapters";
import { InitialInputMaterials, InitialOutputMaterials } from "shared/types";
import { useOrderProcessStepTrackerGeneratePdfFiles } from "shared/hooks";
import { StatusesSummary } from "../StatusesSummary";
import { AddProductionRequest } from "./ProcessStepProduction";

interface SubmitProductionFormInfoProps {
  outputMaterials: ProductionOutputMaterial[];
  isSomeEditModeOpen: boolean;
  isValidProductionForm: boolean;
  processStepId?: number;
  values: AddProductionRequest;
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleCountOfItemsChange: (value: number, index: number) => void;
  adaptedInitialInputMaterials: InitialInputMaterials[];
  adaptedInitialOutputMaterials: InitialOutputMaterials[];
  refetchOrderProcessStepTracker: () => void;
  setIsUnlocked: Dispatch<SetStateAction<Record<number, boolean>>>;
}

const SubmitProductionFormInfo: FC<SubmitProductionFormInfoProps> = ({
  outputMaterials,
  isSomeEditModeOpen,
  isValidProductionForm,
  processStepId,
  values,
  orderStepTrackerResponseItem,
  setFieldValue,
  handleCountOfItemsChange,
  adaptedInitialInputMaterials,
  adaptedInitialOutputMaterials,
  refetchOrderProcessStepTracker,
  setIsUnlocked,
}) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();

  const [updateOrderProcessStepTrackersProduceMaterialItems] =
    useUpdateOrderProcessStepTrackersProduceMaterialItemsMutation();

  const { generateLastSetLabelsPdf } =
    useOrderProcessStepTrackerGeneratePdfFiles({
      orderProcessStepTrackerId: orderStepTrackerResponseItem?.id as number,
    });

  const createOrderStepTrackerRequest = async () => {
    try {
      await updateOrderProcessStepTrackersProduceMaterialItems({
        id: Number(processStepId),
        input_materials: adaptInputMaterialValues(
          values,
          orderStepTrackerResponseItem
        ) as InputMaterialFormik[],
        output_materials: adaptOutputMaterialsValues(
          values,
          orderStepTrackerResponseItem
        ),
      }).unwrap();
      api.success({
        message: t("orders.success"),
        description: t("orders.add-msg"),
      });
      // Set initial values after successful request
      setFieldValue("input_materials", adaptedInitialInputMaterials);
      setFieldValue("output_materials", adaptedInitialOutputMaterials);
      // Clear count of items based on output materials length
      if (outputMaterials && outputMaterials.length > 0) {
        outputMaterials.forEach((_, index) => {
          handleCountOfItemsChange(0, index);
        });
      }
      // Manually refetch data for the process step tracker
      // Because, after request we set <calc_default_dynamic_tracker_fields> field in adaptedInitialOutputMaterials function as default values for this material
      // Using refetch function and not tag, because requests are in different api files and have different invalidate tags for refetch
      setIsUnlocked({});
      refetchOrderProcessStepTracker();
    } catch (error) {
      api.error({
        message: t("orders.error"),
        description: handleRequestError(error, t("orders.error")),
      });
    }
  };

  const createAndPrint = async () => {
    try {
      await createOrderStepTrackerRequest();
      await generateLastSetLabelsPdf();
      api.success({
        message: t("orders.successPdf"),
        description: t("orders.pdfGeneratedSuccessfully"),
      });
      setIsUnlocked({});
    } catch (error) {
      api.error({
        message: t("orders.error"),
        description: handleRequestError(error, t("orders.error")),
      });
    }
  };

  return (
    <>
      {contextHolder}
      <div className="w-full flex items-center justify-center flex-col gap-y-3">
        <StatusesSummary outputMaterials={outputMaterials} />

        {isSomeEditModeOpen ? (
          <Alert
            type="warning"
            message={t("orders.submitEditingBeforeRequest")}
          />
        ) : (
          <>
            <Button
              type="default"
              onClick={createOrderStepTrackerRequest}
              className={classNames("w-52", {
                "bg-green-50": isValidProductionForm,
              })}
              disabled={!isValidProductionForm}
            >
              {t("orders.create")}
            </Button>

            <Button
              type="default"
              onClick={createAndPrint}
              className={classNames("w-52", {
                "bg-blue-50": isValidProductionForm,
              })}
              disabled={!isValidProductionForm}
            >
              {t("orders.createAndPrint")}
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export { SubmitProductionFormInfo };
