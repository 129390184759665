import { useState, useEffect } from "react";
import { useDebouncedTableFilter } from "./useDebouncedTableFilter";

export const useUpdatedMaterialManagementsFilters = () => {
  const savedFilters = JSON.parse(
    localStorage.getItem("materialFilters") || "{}"
  );

  const {
    debouncedValue: debouncedNameContains,
    handleFilterChange: handleNamedValue,
    value: nameValue,
    clearFilterValue: clearNameValue,
  } = useDebouncedTableFilter(savedFilters.name || "", 400);

  const [materialCategoryFilteringItem, setMaterialCategoryFilteringItem] =
    useState<number | string>(savedFilters.materialCategory || "");

  const handleMaterialCategory = (value: number | string) => {
    setMaterialCategoryFilteringItem(value);
  };

  const isVisibleClearFiltersButton =
    !!debouncedNameContains || materialCategoryFilteringItem;

  const clearMaterialCategoryFilter = () => {
    setMaterialCategoryFilteringItem("");
  };

  const clearAllFilters = () => {
    clearNameValue();
    clearMaterialCategoryFilter();
    localStorage.removeItem("materialFilters");
  };

  useEffect(() => {
    const filters = {
      name: nameValue,
      materialCategory: materialCategoryFilteringItem,
    };
    localStorage.setItem("materialFilters", JSON.stringify(filters));
  }, [nameValue, materialCategoryFilteringItem]);

  return {
    debouncedNameContains,
    handleNamedValue,
    nameValue,
    materialCategoryFilteringItem,
    setMaterialCategoryFilteringItem,
    clearAllFilters,
    isVisibleClearFiltersButton,
    clearNameValue,
    handleMaterialCategory,
  };
};
