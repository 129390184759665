import React, { FC } from "react";
import {
  ConfigDynamicTrackerFields,
  DynamicOutputMaterialData,
  DynamicOutputMaterialObject,
  OrderStepTrackerResponseItem,
} from "shared/api";
import { useTranslation } from "react-i18next";
import { UpdateOutputMaterialNumberInput } from "./UpdateOutputMaterialNumberInput";
import { UpdateOutputMaterialTextInput } from "./UpdateOutputMaterialTextInput";
import { UpdateOutputMaterialChoicesSelect } from "./UpdateOutputMaterialChoicesSelect";

interface DynamicFieldsForEditingProps {
  index: number;
  currentPage: number;
  materials: DynamicOutputMaterialData[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
}

const DynamicFieldsForEditing: FC<DynamicFieldsForEditingProps> = ({
  index,
  currentPage,
  materials,
  setFieldValue,
  orderStepTrackerResponseItem,
}) => {
  const { t } = useTranslation("", { keyPrefix: "orders" });

  const renderDynamicFields = (
    configData: ConfigDynamicTrackerFields[],
    dynamicData: DynamicOutputMaterialObject,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    return configData?.map(
      (section: ConfigDynamicTrackerFields, sectionIndex) => (
        <div
          key={sectionIndex}
          className="w-full my-4 p-4 bg-white shadow-md rounded-md"
        >
          {/* Title section */}
          <div className="w-full bg-blue-primary text-white my-3 font-bold">
            {section.title}
          </div>

          {/* Firlds of section */}
          <div className="fields-container mt-3">
            {section.fields.map((field, fieldIndex: number) => {
              const fieldName = `output_materials.${index}.dynamicData.${currentPage}.dynamic_tracker_fields.${section.title}.${field.name}`;
              const fieldValue = dynamicData[section.title]?.[field.name];
              const fieldType = field.type;

              let inputComponent;
              switch (fieldType) {
                case "integer":
                case "float":
                  inputComponent = (
                    <UpdateOutputMaterialNumberInput
                      fieldName={fieldName}
                      fieldValue={fieldValue}
                      setFieldValue={setFieldValue}
                      section={section}
                      cardIndex={fieldIndex}
                      placeholder={
                        field?.type === "float"
                          ? t("enterFloat")
                          : t("enterInteger")
                      }
                    />
                  );
                  break;
                case "text":
                  inputComponent = (
                    <UpdateOutputMaterialTextInput
                      fieldName={fieldName}
                      fieldValue={fieldValue}
                      setFieldValue={setFieldValue}
                      section={section}
                      cardIndex={fieldIndex}
                    />
                  );
                  break;
                case "choices":
                  inputComponent = (
                    <UpdateOutputMaterialChoicesSelect
                      fieldName={fieldName}
                      fieldValue={fieldValue}
                      setFieldValue={setFieldValue}
                      section={section}
                      cardIndex={fieldIndex}
                      field={field}
                    />
                  );
                  break;
                default:
                  inputComponent = null;
              }

              return (
                <div key={fieldIndex} className="field-item mb-3">
                  <span className="field-name block font-medium mb-1">
                    {field?.description || field?.name}
                  </span>
                  {inputComponent}
                </div>
              );
            })}
          </div>
        </div>
      )
    );
  };

  const dynamicData = materials[currentPage]?.dynamic_tracker_fields;

  const configData =
    orderStepTrackerResponseItem?.process_step?.output_materials?.[index]
      ?.material?.material_category?.config_dynamic_tracker_fields;

  return (
    <div className="flex justify-between">
      {dynamicData
        ? renderDynamicFields(configData ?? [], dynamicData, setFieldValue)
        : null}
    </div>
  );
};

export { DynamicFieldsForEditing };
