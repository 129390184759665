import { Modal, notification } from "antd";
import { FormikProvider, useFormik } from "formik";
import React, { FC, useCallback } from "react";
import {
  MaterialStockItem,
  ProductionInputMaterial,
  StockMaterialItem,
  useGetOrderProductionSetQuery,
  useUpdateOrderProductionSetMutation,
} from "shared/api";
import {
  handleRequestError,
  noop,
  returnOrderProductionSetData,
} from "shared/helpers";
import { FormInput, Loader } from "shared/ui";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import { Colors } from "shared/themes";
import { adaptConsumedMaterials } from "shared/adapters";
import { ModalSizeValue, orderProductionSetExpand } from "shared/constants";
import { OrderProductionSetParams } from "shared/types";
import { UpdateOrderProdSetValidation } from "shared/schemas";
import { OrderMaterialConsumed } from "./OrderMaterialConsumed";

interface ChangeOrderProductionSetModalProps {
  isOpen: boolean;
  onClose: () => void;
  orderProductionSetId: number | null;
  materialStockBatches?: StockMaterialItem[];
  materialItems: MaterialStockItem[];
  inputMaterials: ProductionInputMaterial[];
}

const ChangeOrderProductionSetModal: FC<ChangeOrderProductionSetModalProps> = ({
  isOpen,
  onClose,
  orderProductionSetId,
  materialStockBatches,
  inputMaterials,
  materialItems,
}) => {
  const { t } = useTranslation();

  const [api, contextHolder] = notification.useNotification();

  const [updateOrderProductionSet] = useUpdateOrderProductionSetMutation();

  const { data: orderProductionSet, isLoading } = useGetOrderProductionSetQuery(
    {
      id: orderProductionSetId as number,
      expand: orderProductionSetExpand,
    },
    {
      skip: !orderProductionSetId,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    }
  );

  const {
    setNumber,
    orderProcessStepTracker,
    materialsConsumed,
    subSetNumber,
  } = returnOrderProductionSetData(orderProductionSet);

  const orderSetFormik = useFormik<OrderProductionSetParams>({
    initialValues: {
      set_number: setNumber,
      sub_set_number: subSetNumber,
      order_process_step_tracker: orderProcessStepTracker,
      materials_consumed: materialsConsumed,
    },
    enableReinitialize: true,
    validationSchema: UpdateOrderProdSetValidation,
    onSubmit: noop,
  });

  const { values, handleChange, handleBlur, errors, resetForm, isValid } =
    orderSetFormik;

  const closeModal = useCallback(() => {
    onClose();
    resetForm();
  }, [onClose, resetForm]);

  const handleOrderSetUpdate = async () => {
    try {
      await updateOrderProductionSet({
        id: orderProductionSetId as number,
        materials_consumed: adaptConsumedMaterials(values?.materials_consumed),
        set_number: values.set_number as number,
        sub_set_number: Number(values.sub_set_number),
        order_process_step_tracker: orderProcessStepTracker!,
      }).unwrap();

      api.success({
        message: t("orders.success-title"),
        description: t("orders.successfully-updated"),
      });
    } catch (error) {
      api.error({
        message: t("orders.error-title"),
        description: handleRequestError(error, t("orders.error")),
      });
    } finally {
      closeModal();
    }
  };

  if (isLoading) return <Loader />;

  return (
    <>
      {contextHolder}

      <FormikProvider value={orderSetFormik}>
        <Modal
          open={isOpen}
          onCancel={closeModal}
          title={t("orders.change-order-prod-set")}
          width={ModalSizeValue.X_LARGE}
          onOk={handleOrderSetUpdate}
          okButtonProps={{
            disabled: !isValid,
            style: {
              background: `${Colors.blue.primary}`,
            },
          }}
        >
          <div className="w-full flex flex-col gap-y-3">
            <Title level={5}>{t("orders.set-number")}</Title>

            <FormInput
              value={values.set_number!}
              fieldName="set_number"
              placeholder={t("orders.set-number")}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.set_number}
              isError={!!errors.set_number}
            />

            <Title level={5}>{t("orders.sub-set-number")}</Title>

            <FormInput
              value={values.sub_set_number!}
              fieldName="sub_set_number"
              placeholder={t("orders.sub-set-number")}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.sub_set_number}
              isError={!!errors.sub_set_number}
            />
          </div>

          <OrderMaterialConsumed
            materialItems={materialItems}
            inputMaterials={inputMaterials}
            materialStockBatches={materialStockBatches}
          />
        </Modal>
      </FormikProvider>
    </>
  );
};

export { ChangeOrderProductionSetModal };
