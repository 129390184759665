export const expandMaterialConsumedWithUnit =
  "material.material_category.unit,material_stock_batch.material.material_category.unit,material_item.material_stock_batch.material.material_category.unit,order_production_set.order_process_step_tracker.order.product_card";
export const expandFilteredStockTable = "material.material_category.unit";

export enum MaterialItemsSorting {
  CALC_TOTAL_REMAINING_QUANTITY_ASC = "calc_total_remaining_quantity",
  CALC_TOTAL_REMAINING_QUANTITY_DESC = "-calc_total_remaining_quantity",
  CALC_PERCENTAGE_REMAINING_ASC = "calc_percentage_remaining_quantity",
  CALC_PERCENTAGE_REMAINING_DESC = "-calc_percentage_remaining_quantity",
  STATUS_ASC = "status",
  STATUS_DESC = "-status",
}

export const materialConsumedInitialValues = {
  id: null,
  consumed_datetime: null,
  quantity: null,
  order_process_step_tracker: null,
  material: null,
  material_stock_batch: null,
  material_item: null,
  reason: null,
  status: null,
  fieldVariant: null,
};

export enum ConsumedStatuses {
  LO = "LO",
  US = "US",
  WA = "WA",
}

export enum SelectFieldValues {
  MATERIAL = "material",
  MATERIAL_STOCK_BATCH = "material_stock_batch",
  MATERIAL_ITEM = "material_item",
}

export const ConsumedSelectFieldVariant = [
  { value: "material", label: "Material" },
  { value: "material_stock_batch", label: "Material Stock Batch" },
  { value: "material_item", label: "Material item" },
];

export const ConsumedStatusesDropDown = [
  { value: "LO", label: "Lost" },
  { value: "US", label: "Used" },
  { value: "WA", label: "Wasted" },
];

export const columnsMaterialConsumedData = {
  consumedDatetime: {
    title: "materialConsumptionTableColumns.consumedDatetime",
    dataIndex: "consumed_datetime",
    key: "consumed_datetime",
    width: "2%",
  },
  quantity: {
    title: "materialConsumptionTableColumns.quantity",
    dataIndex: "quantity",
    key: "quantity",
    width: "5%",
  },
  order_production_set: {
    title: "materialConsumptionTableColumns.orderProdSet",
    dataIndex: "order_production_set",
    key: "order_production_set",
    width: "5%",
  },
  order_column: {
    title: "materialConsumptionTableColumns.order",
    dataIndex: "order",
    key: "order",
    width: "5%",
  },
  material: {
    title: "materialConsumptionTableColumns.material",
    dataIndex: "material",
    key: "material",
    width: "5%",
  },
  materialStockBatch: {
    title: "materialConsumptionTableColumns.materialStockBatch",
    dataIndex: "material_stock_batch",
    key: "material_stock_batch",
    width: "5%",
  },
  materialItem: {
    title: "materialConsumptionTableColumns.materialItem",
    dataIndex: "material_item",
    key: "material_item",
    width: "5%",
  },
  reason: {
    title: "materialConsumptionTableColumns.reason",
    dataIndex: "reason",
    key: "reason",
    width: "20%",
  },
  status: {
    title: "materialConsumptionTableColumns.status",
    dataIndex: "status",
    key: "status",
    width: "3%",
  },
  actions: {
    title: "materialConsumptionTableColumns.actions",
    key: "actions",
    width: "5%",
  },
};

export const currencies = [
  { value: "USD", label: "USD" },
  { value: "EUR", label: "EUR" },
  { value: "SEK", label: "SEK" },
];
