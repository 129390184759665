import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  CreateMachineItem,
  CreateProcessStep,
  CreateProcessTypeParams,
  CreateProductCard,
  MachineItem,
  MachinesParams,
  MachinesResponse,
  ProcessStepItem,
  ProcessStepParams,
  ProcessStepsResponse,
  ProcessTemplatesParams,
  ProcessTemplateItem,
  ProcessTemplatesResponse,
  ProductCardItem,
  ProductCardsParams,
  ProductCardsResponse,
  UpdateMachineItem,
  UpdateProcessStepItem,
  BuyerParams,
  ProductCardByIdParams,
  AddProcessTemplateImageParams,
  UpdateProcessStepResponse,
  CreateProcessStepResponse,
  CloneProductCard,
} from "./models";
import { ProductCard } from "../orders";

export const productCardApi = createApi({
  baseQuery,
  reducerPath: "productCardApi",
  tagTypes: ["processTemplates", "machines", "productCards", "processSteps"],
  endpoints: (builder) => ({
    getAppProcessTemplates: builder.query<
      ProcessTemplatesResponse,
      ProcessTemplatesParams
    >({
      query: ({ limit, offset, expand, ordering }) => ({
        url: "/api/v1/process_templates/",
        method: "GET",
        params: { limit, offset, expand, ordering },
      }),
      providesTags: ["processTemplates"],
    }),

    getAppProcessTemplateById: builder.query<
      ProcessTemplateItem[],
      ProcessTemplatesParams
    >({
      query: (params) => ({
        url: "/api/v1/process_templates/",
        method: "GET",
        params,
      }),
      providesTags: ["processTemplates"],
    }),

    addProcessTemplateImage: builder.mutation<
      void,
      AddProcessTemplateImageParams
    >({
      query: (body) => ({
        url: `/api/v1/process_templates/${body.id}/image/`,
        method: "POST",
        body: body.image,
        formData: true,
      }),

      invalidatesTags: ["processTemplates"],
    }),

    getAppProcessTemplatesWithNoParams: builder.query<
      ProcessTemplateItem[],
      void
    >({
      query: () => ({
        url: "/api/v1/process_templates/",
        method: "GET",
      }),
      providesTags: ["processTemplates"],
    }),
    createProcessTemplate: builder.mutation<
      CreateProcessStepResponse,
      CreateProcessTypeParams
    >({
      query: (body) => ({
        url: "/api/v1/process_templates/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["processTemplates"],
    }),
    deleteProcessTemplate: builder.mutation<void, ProcessTemplateItem>({
      query: (body) => ({
        url: `/api/v1/process_templates/${body.id}/`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["processTemplates"],
    }),
    updateProcessTemplate: builder.mutation<
      UpdateProcessStepResponse,
      ProcessTemplateItem
    >({
      query: (body) => ({
        url: `/api/v1/process_templates/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["processTemplates"],
    }),

    // machines
    getAllMachines: builder.query<MachinesResponse, MachinesParams>({
      query: (params) => ({
        url: "/api/v1/machines/",
        method: "GET",
        params,
      }),
      providesTags: ["machines"],
    }),
    getMachineById: builder.query<MachineItem, MachinesParams>({
      query: (params) => ({
        url: `/api/v1/machines/${params.id}/`,
        method: "GET",
        params,
      }),
      providesTags: ["machines"],
    }),
    getAllMachinesWithNoParams: builder.query<MachineItem[], void>({
      query: () => ({
        url: "/api/v1/machines/",
        method: "GET",
      }),
      providesTags: ["machines"],
    }),
    createMachine: builder.mutation<void, CreateMachineItem>({
      query: (body) => ({
        url: "/api/v1/machines/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["machines"],
    }),
    deleteMachine: builder.mutation<void, MachineItem>({
      query: (body) => ({
        url: `/api/v1/machines/${body.id}/`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["machines"],
    }),
    updateMachine: builder.mutation<void, UpdateMachineItem>({
      query: (body) => ({
        url: `/api/v1/machines/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["machines"],
    }),

    // product cards
    getAllProductCards: builder.query<ProductCardsResponse, ProductCardsParams>(
      {
        query: (params) => ({
          url: "/api/v1/product_cards/",
          method: "GET",
          params,
        }),
        providesTags: ["productCards"],
      }
    ),

    getProductCardById: builder.query<ProductCard[], ProductCardByIdParams>({
      query: (params) => ({
        url: "/api/v1/product_cards/",
        method: "GET",
        params,
      }),
      providesTags: ["productCards"],
    }),

    getAllProductCardsWithNoParams: builder.query<
      ProductCardItem[],
      BuyerParams
    >({
      query: (body) => ({
        url: "/api/v1/product_cards/",
        method: "GET",
        params: body,
      }),
      providesTags: ["productCards"],
    }),
    createProductCard: builder.mutation<void, CreateProductCard>({
      query: (body) => ({
        url: "/api/v1/product_cards/",
        method: "POST",
        body: { ...body },
      }),
      invalidatesTags: ["productCards"],
    }),
    updateProductCard: builder.mutation<void, ProductCardItem>({
      query: (body) => ({
        url: `/api/v1/product_cards/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["productCards"],
    }),
    deleteProductCard: builder.mutation<void, ProductCardItem>({
      query: (body) => ({
        url: `/api/v1/product_cards/${body.id}/`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["productCards"],
    }),

    cloneProductCard: builder.mutation<ProductCard, CloneProductCard>({
      query: (body) => ({
        url: `/api/v1/product_cards/${body.id}/clone/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["productCards", "processSteps"],
    }),

    archiveProductCard: builder.mutation<
      void,
      Pick<ProductCardItem, "id" | "is_archived">
    >({
      query: ({ id, is_archived }) => ({
        url: `/api/v1/product_cards/${id}/`,
        method: "PATCH",
        body: { is_archived },
      }),
      invalidatesTags: ["productCards"],
    }),

    // process step
    getProcessSteps: builder.query<ProcessStepsResponse, ProcessStepParams>({
      query: ({
        limit,
        offset,
        expand,
        ordering,
        name__icontains,
        process_template,
      }) => ({
        url: "/api/v1/process_steps/",
        method: "GET",
        params: {
          limit,
          offset,
          expand,
          ordering,
          name__icontains,
          process_template,
        },
      }),
      providesTags: ["processSteps"],
    }),

    getProcessStepById: builder.query<ProcessStepItem[], ProcessStepParams>({
      query: (params) => ({
        url: "/api/v1/process_steps/",
        method: "GET",
        params,
      }),
      providesTags: ["processSteps"],
    }),

    getProcessStepsWithNoParams: builder.query<ProcessStepItem[], void>({
      query: () => ({
        url: "/api/v1/process_steps/",
        method: "GET",
      }),
      providesTags: ["processSteps"],
    }),
    createProcessStep: builder.mutation<void, CreateProcessStep>({
      query: (body) => ({
        url: "/api/v1/process_steps/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["processSteps"],
    }),
    deleteProcessStep: builder.mutation<void, ProcessStepItem>({
      query: (body) => ({
        url: `/api/v1/process_steps/${body.id}/`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["processSteps"],
    }),
    updateProcessStep: builder.mutation<void, UpdateProcessStepItem>({
      query: (body) => ({
        url: `/api/v1/process_steps/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["processSteps"],
    }),
  }),
});

export const {
  // process templates
  useGetAppProcessTemplatesQuery,
  useGetAppProcessTemplatesWithNoParamsQuery,
  useCreateProcessTemplateMutation,
  useDeleteProcessTemplateMutation,
  useUpdateProcessTemplateMutation,
  useGetAppProcessTemplateByIdQuery,
  useAddProcessTemplateImageMutation,
  // machines
  useGetAllMachinesQuery,
  useGetMachineByIdQuery,
  useCreateMachineMutation,
  useDeleteMachineMutation,
  useUpdateMachineMutation,
  useGetAllMachinesWithNoParamsQuery,
  // product cards
  useGetAllProductCardsQuery,
  useCreateProductCardMutation,
  useUpdateProductCardMutation,
  useDeleteProductCardMutation,
  useGetAllProductCardsWithNoParamsQuery,
  useGetProductCardByIdQuery,
  useCloneProductCardMutation,
  useArchiveProductCardMutation,
  // process steps
  useGetProcessStepsQuery,
  useGetProcessStepsWithNoParamsQuery,
  useCreateProcessStepMutation,
  useDeleteProcessStepMutation,
  useUpdateProcessStepMutation,
  useGetProcessStepByIdQuery,
} = productCardApi;
