import { useDateFilters } from "./useDateFilters";
import { useDebouncedTableFilter } from "./useDebouncedTableFilter";

export const useQualityResultsFilters = () => {
  const {
    debouncedValue: debouncedNameValue,
    handleFilterChange: handleNameValue,
    value: nameValue,
    clearFilterValue: clearNameValue,
  } = useDebouncedTableFilter("", 400);

  const {
    debouncedValue: debouncedBatchCodeValue,
    handleFilterChange: handleBatchCodeValue,
    value: batchCodeValue,
    clearFilterValue: clearBatchCodeValue,
  } = useDebouncedTableFilter("", 400);

  const {
    debouncedValue: debouncedSerialNumberValue,
    handleFilterChange: handleSerialNumberValue,
    value: serialNumberValue,
    clearFilterValue: clearSerialNumberValue,
  } = useDebouncedTableFilter("", 400);

  const {
    debouncedValue: debouncedSupplierValue,
    handleFilterChange: handleSupplierValue,
    value: supplierValue,
    clearFilterValue: clearSupplierValue,
  } = useDebouncedTableFilter("", 400);

  const {
    debouncedValue: debouncedMaterialGradeValue,
    handleFilterChange: handleMaterialGradeValue,
    value: materialGradeValue,
    clearFilterValue: clearMaterialGradeValue,
  } = useDebouncedTableFilter("", 400);

  const {
    dateGreater,
    dateLesser,
    handleDateGreater,
    handleDateLesser,
    clearDates,
    isDataFilterActive,
  } = useDateFilters();

  const isActiveFiltering =
    nameValue.length ||
    batchCodeValue.length ||
    serialNumberValue.length ||
    supplierValue.length ||
    isDataFilterActive;

  const clearAllFilters = () => {
    clearNameValue();
    clearBatchCodeValue();
    clearSerialNumberValue();
    clearSupplierValue();
    clearDates();
  };

  return {
    nameValue,
    debouncedNameValue,
    handleNameValue,
    clearNameValue,
    serialNumberValue,
    debouncedSerialNumberValue,
    handleSerialNumberValue,
    clearSerialNumberValue,
    supplierValue,
    debouncedSupplierValue,
    handleSupplierValue,
    clearSupplierValue,
    dateGreater,
    handleDateGreater,
    dateLesser,
    handleDateLesser,
    clearDates,
    isActiveFiltering,
    clearAllFilters,
    isDataFilterActive,
    debouncedBatchCodeValue,
    clearBatchCodeValue,
    handleBatchCodeValue,
    batchCodeValue,
    debouncedMaterialGradeValue,
    handleMaterialGradeValue,
    materialGradeValue,
    clearMaterialGradeValue,
  };
};
