import classNames from "classnames";
import React, { FC } from "react";
import { useWindowDimensions } from "shared/hooks";
import { OrderStepTrackerResponseItem } from "shared/api";
import { DynamicStepsContainer } from "./DynamicStepsContainer";

interface ProdPageInfoBlockProps {
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
}

const ProdPageInfoBlock: FC<ProdPageInfoBlockProps> = ({
  orderStepTrackerResponseItem,
}) => {
  const { isDesktop } = useWindowDimensions();

  return (
    <div
      className={classNames(
        "w-full h-[100%] flex gap-x-5 items-start justify-between",
        {
          "flex-col gap-y-3": !isDesktop,
        }
      )}
    >
      <DynamicStepsContainer
        orderStepTrackerResponseItem={orderStepTrackerResponseItem}
      />
    </div>
  );
};

export { ProdPageInfoBlock };
