import { useFormikContext } from "formik";
import React, { FC } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { FormInput } from "shared/ui";
import { getConsumptionType, renderDynamicQuantity } from "shared/helpers";
import { ProductionInputMaterial } from "shared/api";
import { useTranslation } from "react-i18next";
import { AddDynamicOrderSteps } from "shared/types";

interface DynamicQuantityDropDownProps {
  inputIndex: number;
  fieldIndex: number;
  inputMaterial: ProductionInputMaterial;
  removeItem: (fieldIndex: number) => void;
  isUnlocked: Record<number, boolean>;
  toggleUnlockField: (inputIndex: number) => void;
}

const DynamicQuantityDropDown: FC<DynamicQuantityDropDownProps> = ({
  inputIndex,
  fieldIndex,
  inputMaterial,
  removeItem,
  isUnlocked,
  toggleUnlockField,
}) => {
  const { t } = useTranslation();

  const { values, handleBlur, setFieldValue } =
    useFormikContext<AddDynamicOrderSteps>();

  const { isFixed, isProportional } = getConsumptionType(
    inputMaterial.consumptionType
  );

  const quantityValue =
    values.input_materials?.[inputIndex]?.fields?.[fieldIndex]?.quantity;

  const quantityFieldName = `input_materials.${inputIndex}.fields.${fieldIndex}.quantity`;

  const isUnlockedForField = isUnlocked[inputIndex];

  const toggleUnlock = () => {
    toggleUnlockField(inputIndex);
    if (isUnlockedForField) {
      setFieldValue(quantityFieldName, quantityValue);
    }
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(quantityFieldName, e.target.value);
  };

  const disableInput = !isUnlockedForField && (isFixed || isProportional);

  return (
    <>
      <div className="flex h-auto items-center justify-center gap-2">
        <FormInput
          value={
            isUnlockedForField
              ? quantityValue
              : renderDynamicQuantity(
                  inputMaterial,
                  inputMaterial?.consumptionType,
                  quantityValue
                )
          }
          fieldName={quantityFieldName}
          placeholder={t("orders.enter-quantity")}
          handleChange={handleQuantityChange}
          handleBlur={handleBlur}
          numberType
          disabled={disableInput}
        />
        {(isFixed || isProportional) && (
          <button
            type="button"
            onClick={toggleUnlock}
            className={`h-full px-2 py-1 rounded ${
              isUnlockedForField
                ? "bg-red-300 text-white"
                : "bg-green-500 text-white"
            }`}
          >
            {isUnlockedForField ? t("orders.lock") : t("orders.unlock")}
          </button>
        )}
      </div>

      <div className="w-full flex items-center justify-center">
        {inputMaterial?.fields.length > 1 && (
          <DeleteOutlined
            onClick={() => removeItem(fieldIndex)}
            className="btn-remove"
          />
        )}
      </div>
    </>
  );
};

export { DynamicQuantityDropDown };
