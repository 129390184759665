import { Button } from "antd";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface RenderClearFiltersButtonProps {
  onClick: () => void;
}

const RenderClearFiltersButton: FC<RenderClearFiltersButtonProps> = ({
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex items-center">
      <Button className="-mt-2 mb-2" onClick={onClick}>
        {t("clear-filters.all")}
      </Button>
    </div>
  );
};

export { RenderClearFiltersButton };
