import Title from "antd/es/typography/Title";
import { FormikProvider, useFormik } from "formik";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  useGetAllCompaniesQuery,
  useGetProcessStepsWithNoParamsQuery,
  useGetProductCardByIdQuery,
  useUpdateProductCardMutation,
} from "shared/api";
import { FormInput, HyperlinkPageHeader, Loader } from "shared/ui";
import { handleRequestError, noop } from "shared/helpers";
import { Button, notification, Table } from "antd";
import { useModal } from "shared/hooks";
import { adaptProcessSteps } from "shared/adapters";
import { AddOrEditProductCardSchema } from "shared/schemas";
import { useGetCompanyTableColumns } from "shared/hooks/useGetCompaniesTable";
import { ProcessStepToTheCardActionsModal, ProcessStepsTable } from "./ui";

const ProductCardHyperlink = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();

  const [reorderingIds, setReorderingIds] = useState<number[]>([]);
  const [updateProductCard] = useUpdateProductCardMutation();
  const { data: companies, isLoading: isLoadingCompanies } =
    useGetAllCompaniesQuery({ product_card: Number(id) });

  const companyColumns = useGetCompanyTableColumns({
    t,
  });

  const [
    isOpenAddProcessStepModal,
    showProcessStepsModal,
    hideProcessStepsModal,
  ] = useModal();

  const { data: productCard, isLoading: isLoadingProductCard } =
    useGetProductCardByIdQuery(
      { id: Number(id), expand: "company" },
      { skip: !id }
    );

  const { data: processSteps, isLoading: isLoadingProcessSteps } =
    useGetProcessStepsWithNoParamsQuery();

  const adaptedSteps = useMemo(() => {
    return adaptProcessSteps(processSteps || []);
  }, [processSteps]);

  const addOrUpdateProductCardFormik = useFormik({
    initialValues: { ...productCard?.[0] },
    validationSchema: AddOrEditProductCardSchema,
    onSubmit: noop,
    enableReinitialize: true,
  });

  const { values, handleBlur, handleChange, errors, isValid } =
    addOrUpdateProductCardFormik;

  const updateProductCardRequest = async () => {
    try {
      await updateProductCard({
        id: values.id!,
        name: values.name!,
        process_steps: reorderingIds,
      }).unwrap();
      api.success({
        message: t("product-card.success-title"),
        description: t("product-card.success-update-msg"),
      });
    } catch (error) {
      api.error({
        message: t("product-card.error"),
        description: handleRequestError(error, t("product-card.error")),
      });
    }
  };

  if (isLoadingProductCard || isLoadingProcessSteps) {
    return <Loader type="fullscreen" />;
  }

  return (
    <>
      {contextHolder}
      <FormikProvider value={addOrUpdateProductCardFormik}>
        <div className="h-auto w-screen flex items-center justify-center flex-col ">
          <HyperlinkPageHeader
            title={`Product Card Hyperlink - ${productCard?.[0]?.name}`}
            isValid={isValid}
            onOk={updateProductCardRequest}
          />

          <div className="p-4 w-full">
            <Title level={5} className="mb-1.25">
              {t("product-card.card-name")}
            </Title>

            <FormInput
              value={values.name}
              fieldName="name"
              placeholder={t("product-card.name-placeholder")}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.name}
              isError={!!errors.name}
            />

            {isLoadingCompanies ? (
              <Loader />
            ) : (
              <>
                <Title className="mb-3.75 mt-6" level={5}>
                  {t("companies.companies-table-title")}
                </Title>
                <Table
                  dataSource={companies}
                  columns={companyColumns}
                  rowKey="id"
                  pagination={false}
                />
              </>
            )}

            <Button
              type="primary"
              className="btn-primary--dark mt-2.5"
              onClick={showProcessStepsModal}
            >
              {t("product-card.add-step")}
            </Button>

            <Title className="mb-3.75" level={5}>
              {t("product-card.process-steps")}
            </Title>

            {isLoadingProcessSteps ? (
              <Loader />
            ) : (
              <ProcessStepsTable
                processSteps={processSteps}
                setReorderingIds={setReorderingIds}
              />
            )}

            <ProcessStepToTheCardActionsModal
              isOpenModal={isOpenAddProcessStepModal}
              hideProcessStepsModal={hideProcessStepsModal}
              adaptedSteps={adaptedSteps}
            />
          </div>
        </div>
      </FormikProvider>
    </>
  );
};

export { ProductCardHyperlink };
