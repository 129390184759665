import React, { useState } from "react";
import { Modal, Select, Input, Form } from "antd";
import { useTranslation } from "react-i18next";
import {
  MaterialStockItem,
  ProductionInputMaterial,
  StockMaterialItem,
} from "shared/api";
import { variantSelectValues } from "shared/constants";
import { MaterialVariant } from "./models/MaterialItemsEnum";

const { Option } = Select;

interface MaterialModalProps {
  isOpenModal: boolean;
  close: () => void;
  materialItems: MaterialStockItem[];
  materialStockBatches?: StockMaterialItem[];
  inputMaterials: ProductionInputMaterial[];
  onAddMaterial: (
    variant: MaterialVariant,
    itemId: number | null,
    itemValue: string | null,
    quantity: number | null
  ) => void;
}

interface OptionsMaterialProps {
  id: number;
  label: string;
  value: number;
}

const AddNewMaterialItemModal: React.FC<MaterialModalProps> = ({
  isOpenModal,
  close,
  materialStockBatches,
  inputMaterials,
  onAddMaterial,
  materialItems,
}) => {
  const { t } = useTranslation();
  const [selectedVariant, setSelectedVariant] =
    useState<MaterialVariant | null>(null);
  const [selectedItemLabel, setSelectedItemLabel] = useState<string | null>(
    null
  );
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [quantity, setQuantity] = useState<number | null>(null);

  const uniqueMaterialItems = materialItems.filter(
    (item, index, array) =>
      index === array.findIndex((i) => i.serial_number === item.serial_number)
  );

  const filteredMaterialStockBatches = materialStockBatches?.filter((batch) =>
    inputMaterials.some((material) => material.id === batch.material.id)
  );

  const optionsMaterial: OptionsMaterialProps[] = inputMaterials.map(
    (item) => ({
      id: item.material.id,
      label: item.material.name,
      value: item.material.id,
    })
  );

  const optionsMaterialItems: OptionsMaterialProps[] =
    filteredMaterialStockBatches?.map((item) => ({
      id: item.id ?? 0,
      label: `${item.material.name} (Batch Code: ${item.batch_code}, Delivery: ${item.delivery_date})`,
      value: item.id ?? 0,
    })) ?? [];

  return (
    <Modal
      title={t("AddMaterialItemsTable.title")}
      open={isOpenModal}
      onOk={() => {
        onAddMaterial(
          selectedVariant!,
          selectedItem,
          selectedItemLabel,
          quantity
        );
        close();
      }}
      onCancel={close}
      okButtonProps={{
        style: { backgroundColor: "#1890ff", color: "#fff" },
      }}
    >
      <Form layout="vertical">
        <Form.Item label={t("AddMaterialItemsTable.variant")}>
          <Select
            placeholder={t("AddMaterialItemsTable.variantPlaceholder")}
            style={{ width: "100%" }}
            onChange={(value) => setSelectedVariant(value)}
            value={selectedVariant}
          >
            {variantSelectValues.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {selectedVariant === MaterialVariant.Material && (
          <>
            <Form.Item label={t("AddMaterialItemsTable.materialItem")}>
              <Select
                placeholder={t("AddMaterialItemsTable.optionSelect")}
                style={{ width: "100%" }}
                onChange={(value) => {
                  setSelectedItem(value);
                  const selectedOption = optionsMaterial.find(
                    (option) => option.id === value
                  );
                  setSelectedItemLabel(selectedOption?.label || "");
                }}
              >
                {optionsMaterial.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t("AddMaterialItemsTable.quantity")}>
              <Input
                onChange={(e) => setQuantity(Number(e.target.value))}
                placeholder={
                  t("AddMaterialItemsTable.quantityPlaceholder") || ""
                }
              />
            </Form.Item>
          </>
        )}

        {selectedVariant === MaterialVariant.StockBatch && (
          <>
            <Form.Item label={t("AddMaterialItemsTable.materialStockBatch")}>
              <Select
                placeholder={t("AddMaterialItemsTable.optionSelect")}
                style={{ width: "100%" }}
                onChange={(value) => {
                  setSelectedItem(value);
                  const selectedOption = optionsMaterial.find(
                    (option) => option.id === value
                  );
                  setSelectedItemLabel(selectedOption?.label || "");
                }}
              >
                {Array.from(
                  new Set(optionsMaterialItems.map((option) => option.id))
                ).map((uniqueId) => {
                  const matchedOption = optionsMaterialItems.find(
                    (option) => option.id === uniqueId
                  );
                  return (
                    <Option key={uniqueId} value={uniqueId}>
                      {matchedOption?.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label={t("AddMaterialItemsTable.quantity")}>
              <Input
                onChange={(e) => setQuantity(Number(e.target.value))}
                placeholder={
                  t("AddMaterialItemsTable.quantityPlaceholder") || ""
                }
              />
            </Form.Item>
          </>
        )}

        {selectedVariant === MaterialVariant.MaterialItem && (
          <>
            <Form.Item label={t("AddMaterialItemsTable.materialItem")}>
              <Select
                placeholder={t("AddMaterialItemsTable.optionSelect")}
                style={{ width: "100%" }}
                onChange={(value) => {
                  const selectedOption = uniqueMaterialItems.find(
                    (option) => option.serial_number === value
                  );
                  setSelectedItem(selectedOption?.id || null);
                  setSelectedItemLabel(selectedOption?.serial_number || null);
                }}
              >
                {uniqueMaterialItems.map((option) => (
                  <Option key={option.id} value={option.serial_number}>
                    {option.serial_number}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t("AddMaterialItemsTable.quantity")}>
              <Input
                placeholder={
                  t("AddMaterialItemsTable.quantityPlaceholder") || ""
                }
                type="number"
                value={quantity ?? ""}
                onChange={(e) => setQuantity(Number(e.target.value))}
              />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export { AddNewMaterialItemModal };
