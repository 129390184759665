interface DownloadPdfProps {
  pdfUrl: string | null;
  nameToSetToPdf?: string;
}

export const useDownLoadPdfFile = ({
  pdfUrl,
  nameToSetToPdf,
}: DownloadPdfProps) => {
  const downloadPdfFile = () => {
    if (!pdfUrl) return;

    const link = document.createElement("a");
    link.href = pdfUrl;

    link.download = nameToSetToPdf
      ? `${nameToSetToPdf} Report.pdf`
      : "downloadedPdf.pdf";

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  return { downloadPdfFile };
};
