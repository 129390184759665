import { ColumnsType } from "antd/es/table";
import { TFunction } from "i18next";
import { SupplierCompanyItem } from "shared/api";
import { companiesColumnsData } from "shared/constants";
import { openHyperlinkInTab } from "shared/helpers";
import { Routes } from "shared/routers";
import { PopConfirmButton } from "shared/ui";

interface UseGetCompanyTableColumnsProps {
  t: TFunction;
}

const { name, address, contactName, information } = companiesColumnsData;

export const useGetCompanyTableColumns = ({
  t,
}: UseGetCompanyTableColumnsProps): ColumnsType<SupplierCompanyItem> => [
  {
    title: t(name.title),
    dataIndex: name.dataIndex,
    key: name.key,
    render: (text: string, item: SupplierCompanyItem) => {
      const redirectLink = `${Routes.companies.hyperlink}/${item?.id}`?.replace(
        ":companyID/",
        ""
      );

      return (
        <PopConfirmButton
          buttonContent={text}
          onClick={() => openHyperlinkInTab(redirectLink)}
        />
      );
    },
  },
  {
    title: t(address.title),
    dataIndex: address.dataIndex,
    key: address.key,
  },
  {
    title: t(contactName.title),
    key: contactName.key,
  },
  {
    title: t(information.title),
    key: information.key,
  },
];
