import React, { FC } from "react";
import { ProductCardItem, ProcessStepItem } from "shared/api";
import { openHyperlinkInTab } from "shared/helpers";
import { Routes } from "shared/routers";
import { PopConfirmButton } from "shared/ui";

interface ProcessStepsByProductCardProps {
  item: ProductCardItem;
}

const ProcessStepsByProductCard: FC<ProcessStepsByProductCardProps> = ({
  item,
}) => {
  return (
    <div className="flex flex-col flex-wrap gap-x-2">
      {Array.isArray(item.process_steps) &&
        item.process_steps.map((step) => {
          const isObject = typeof step === "object" && step !== null;
          const stepId = isObject ? (step as ProcessStepItem).id : step;
          const stepName = isObject
            ? (step as ProcessStepItem).name
            : `Step ${step}`;

          const hyperlink =
            `${Routes.processSteps.hyperlink}/${stepId}`.replace(":id/", "");

          return (
            <PopConfirmButton
              key={stepId}
              buttonContent={stepName}
              onClick={() => openHyperlinkInTab(hyperlink)}
            />
          );
        })}
    </div>
  );
};

export { ProcessStepsByProductCard };
