import React, { FC } from "react";
import { ProductCardItem, ProcessStepItem } from "shared/api";

interface ProcessStepsByProductCardProps {
  item: ProductCardItem;
}

const CustomerByProductCard: FC<ProcessStepsByProductCardProps> = ({
  item,
}) => {
  const customers = Array.isArray(item.process_steps)
    ? item.process_steps
        .filter(
          (step): step is ProcessStepItem =>
            typeof step === "object" && step !== null
        )
        .map((step) => ({
          name: step.dynamic_process_step_fields?.Misc?.end_customer_name,
          id: step.id,
        }))
        .filter(
          (customer, index, self) =>
            customer.name &&
            self.findIndex((c) => c.name === customer.name) === index
        )
    : [];

  return (
    <div className="flex flex-col gap-y-2">
      {customers.length > 0 ? (
        customers.map((customer) => (
          <span className="text-gray-600" key={customer.id}>
            {customer.name}
          </span>
        ))
      ) : (
        <span className="text-sm text-red-100">N/A</span>
      )}
    </div>
  );
};

export { CustomerByProductCard };
