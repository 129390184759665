import React from "react";
import { Button, Image } from "antd";
import { useTranslation } from "react-i18next";
import { useLogoutMutation } from "shared/api";
import { logosImages } from "shared/assets";
import { LanguageSelection } from "./LanguageSelection";
import ThemeSwitcher from "./ThemeSwitcher";

const SidebarFooter = () => {
  const { t } = useTranslation();

  const SUPPORT_PAGE_LINK = "https://www.chainlinx.se/support";

  const [logout] = useLogoutMutation();

  return (
    <div className="w-full flex flex-col items-center gap-y-5">
      <LanguageSelection />

      <Button
        type="default"
        className="w-80% mx-auto bg-white"
        onClick={() => logout()}
      >
        {t("sidebar.logout")}
      </Button>

      <ThemeSwitcher />

      <div className="w-[80%] h-auto bg-white flex items-center justify-center p-2 dark:!bg-white">
        <a
          className="dark:!bg-white"
          href={SUPPORT_PAGE_LINK}
          target="_blank"
          rel="noreferrer"
        >
          <Image
            className="dark:!bg-white"
            src={logosImages.poweredByChainlinx}
            preview={false}
            width={160}
          />
        </a>
      </div>
    </div>
  );
};

export { SidebarFooter };
