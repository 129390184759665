import { Input, Select } from "antd";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useItemViewMaterialCategoryData } from "shared/hooks";
import { Loader, Page } from "shared/ui";
import { ItemViewTable } from "./ui";

const ItemView = () => {
  const { t } = useTranslation("", { keyPrefix: "itemView" });

  const [globalSerialNumber, setGlobalSerialNumber] = useState("");

  const handleGlobalSerialNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setGlobalSerialNumber(e.target.value);
  };

  const {
    isLoadingMaterialCategories,
    adaptedMaterialCategories,
    handleSelectMaterialCategory,
    selectedMaterialCategoryItem,
  } = useItemViewMaterialCategoryData();

  return (
    <Page title={t("title")}>
      {isLoadingMaterialCategories && <Loader type="fullscreen-backdrop" />}

      <div className="flex items-center gap-5 mb-4">
        <div>
          <Title level={5}>{t("selectMaterialCategory")}</Title>

          <Select
            options={adaptedMaterialCategories}
            className="w-full"
            placeholder={t("selectMaterialCategory")}
            onChange={handleSelectMaterialCategory}
            value={selectedMaterialCategoryItem?.id || null}
          />
        </div>
        <div>
          <Title level={5}>{t("selectSerialNumber")}</Title>
          <Input
            className="w-full"
            placeholder={t("selectSerialNumber") || ""}
            value={globalSerialNumber}
            onChange={handleGlobalSerialNumberChange}
          />
        </div>
      </div>

      <ItemViewTable
        globalSerialNumber={globalSerialNumber}
        selectedMaterialCategoryItem={selectedMaterialCategoryItem ?? null}
        setGlobalSerialNumber={setGlobalSerialNumber}
        handleSelectMaterialCategory={handleSelectMaterialCategory}
      />
    </Page>
  );
};

export { ItemView };
