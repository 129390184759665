import { Button, Modal, notification, Table } from "antd";
import React, { FC, useState } from "react";
import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { FormInput, Loader } from "shared/ui";
import { Colors } from "shared/themes";
import { handleRequestError } from "shared/helpers";
import { useModal } from "shared/hooks";
import { AdaptedValues } from "shared/types";
import { ModalSizeValue } from "shared/constants";
import { useGetAllCompaniesQuery } from "shared/api";
import { useGetCompanyTableColumns } from "shared/hooks/useGetCompaniesTable";
import { AddProductCardValues } from "../ProductCards";
import { useUpdateProductCardMutation } from "../../../../shared/api/productCard/productCardApi";
import { ProcessStepsTable } from "./ProcessStepsTable";
import { ProcessStepItem } from "../../../../shared/api/productCard/models";
import { ProcessStepToTheCardActionsModal } from "./AddProcessStepToTheCardModal";

interface EditProductCardModalProps {
  isOpenModal: boolean;
  hideEditProductCardModal: () => void;
  adaptedSteps: AdaptedValues[];
  processSteps?: ProcessStepItem[];
  isLoadingProcessSteps: boolean;
}

const EditProductCardModal: FC<EditProductCardModalProps> = ({
  isOpenModal,
  hideEditProductCardModal,
  adaptedSteps,
  processSteps,
  isLoadingProcessSteps,
}) => {
  const { values, resetForm, handleBlur, handleChange, isValid, errors } =
    useFormikContext<AddProductCardValues>();

  const { t } = useTranslation();

  const [api, contextHolder] = notification.useNotification();

  const [updateProductCard] = useUpdateProductCardMutation();

  const { data: companies, isLoading: isLoadingCompanies } =
    useGetAllCompaniesQuery({ product_card: values.id! });

  const companyColumns = useGetCompanyTableColumns({
    t,
  });

  const [
    isOpenAddProcessStepModal,
    showProcessStepsModal,
    hideProcessStepsModal,
  ] = useModal();

  const [reorderingIds, setReorderingIds] = useState<number[]>([]);

  const closeModal = () => {
    hideEditProductCardModal();
    resetForm();
  };

  const updateProductCardRequest = async () => {
    try {
      await updateProductCard({
        id: values.id!,
        name: values.name!,
        process_steps: reorderingIds,
      }).unwrap();
      api.success({
        message: t("product-card.success-title"),
        description: t("product-card.success-update-msg"),
      });
      closeModal();
    } catch (error) {
      const description = handleRequestError(error, t("product-card.error"));

      api.error({
        message: t("product-card.error"),
        description,
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={t("product-card.edit-card-title")}
        open={isOpenModal}
        width={ModalSizeValue.XL_MEDIUM}
        okButtonProps={{
          disabled: !isValid,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
        onCancel={closeModal}
        onOk={() => {
          updateProductCardRequest();
        }}
      >
        {isLoadingProcessSteps ? (
          <Loader />
        ) : (
          <>
            <Title level={5} className="mb-1.25">
              {t("product-card.card-name")}
            </Title>

            <FormInput
              value={values.name}
              fieldName="name"
              placeholder={t("product-card.name-placeholder")}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.name}
              isError={!!errors.name}
            />

            {isLoadingCompanies ? (
              <Loader />
            ) : (
              <>
                <Title className="mb-3.75 mt-6" level={5}>
                  {t("companies.companies-table-title")}
                </Title>
                <Table
                  dataSource={companies}
                  columns={companyColumns}
                  rowKey="id"
                  pagination={false}
                />
              </>
            )}

            {isLoadingProcessSteps ? (
              <Loader />
            ) : (
              <>
                <Button
                  type="primary"
                  className="btn-primary--dark mt-8"
                  onClick={showProcessStepsModal}
                >
                  {t("product-card.add-step")}
                </Button>
                <Title className="mb-3.75" level={5}>
                  {t("product-card.process-steps")}
                </Title>
                <ProcessStepsTable
                  processSteps={processSteps}
                  setReorderingIds={setReorderingIds}
                />
              </>
            )}

            <ProcessStepToTheCardActionsModal
              isOpenModal={isOpenAddProcessStepModal}
              hideProcessStepsModal={hideProcessStepsModal}
              adaptedSteps={adaptedSteps}
            />
          </>
        )}
      </Modal>
    </>
  );
};

export { EditProductCardModal };
