import { expandDimensionUnits } from "shared/constants";
import { useMemo } from "react";
import {
  adaptCompanies,
  adaptDimensionUnitsData,
  adaptMaterialCategories,
  adaptProductCard,
} from "shared/adapters";
import {
  useGetAllCompaniesQuery,
  useGetAllProductCardsWithNoParamsQuery,
  useGetDimensionUnitsQuery,
  useGetMaterialCategoriesWithNoParamsQuery,
} from "shared/api";

// interface useCompaniesOptionsProps {
//   productCardNameValue: string;
// }

export const useCompaniesOptions = () => {
  const { data: dimensionUnits } = useGetDimensionUnitsQuery({
    expand: expandDimensionUnits,
  });

  const unitsWithLabel = useMemo(() => {
    return adaptDimensionUnitsData(dimensionUnits || []);
  }, [dimensionUnits]);

  const { data: productCards, isLoading: isLoadingProductCards } =
    useGetAllProductCardsWithNoParamsQuery({
      // name__icontains: productCardNameValue,
    });

  const adaptedProductCard = useMemo(() => {
    return adaptProductCard(productCards || []);
  }, [productCards]);

  const { data: materialCategories } =
    useGetMaterialCategoriesWithNoParamsQuery({ expand: "unit" });

  const adaptedMaterialCategories = useMemo(() => {
    return adaptMaterialCategories(materialCategories || []);
  }, [materialCategories]);

  const { data: companies } = useGetAllCompaniesQuery({
    is_parent_company: true,
  });

  const parentCompanies = useMemo(() => {
    return adaptCompanies(companies || []);
  }, [companies]);

  return {
    adaptedProductCard,
    adaptedMaterialCategories,
    parentCompanies,
    //
    productCards,
    materialCategories,
    unitsWithLabel,
    isLoadingProductCards,
  };
};
