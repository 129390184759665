import classNames from "classnames";
import React, { FC, useMemo } from "react";
import { Loader } from "shared/ui";
import { renderDynamicSelectValues } from "shared/helpers";
import { AdaptedValues, InitialInputMaterials } from "shared/types";
import {
  InputMaterialCategory,
  MaterialStockItem,
  ProductionInputMaterial,
  StockMaterialItem,
} from "shared/api";
import { variantSelectValues } from "shared/constants";
import { SelectValueDropdown } from "./SelectValueDropdown";
import { DynamicInputMaterialVariant } from "./DynamicInputMaterialVariant";
import { DynamicInputMaterialQuantity } from "./DynamicInputMaterialQuantity";
import { AddProductionRequest } from "./ProcessStepProduction";

interface ProductionInputMaterialsFormProps {
  values: AddProductionRequest;
  inputIndex: number;
  fieldIndex: number;
  adaptedMaterials: AdaptedValues[];
  adaptedMaterialItems: AdaptedValues[];
  adaptedStockMaterialBatches: AdaptedValues[];
  adaptedInitialInputMaterials: InitialInputMaterials[];
  materialStockBatches?: StockMaterialItem[];
  materialItems?: MaterialStockItem[];
  moreThanOneMaterial: boolean;
  isLoadingInputMaterialsData?: boolean;
  inputMaterial: ProductionInputMaterial;
  removeCard: <T>(index: number) => T | undefined;
  dynamicInputMaterialCategory?: InputMaterialCategory;
  isUnlocked: Record<number, boolean>;
  toggleUnlockField: (inputIndex: number) => void;
}

const ProductionInputMaterialsForm: FC<ProductionInputMaterialsFormProps> = ({
  values,
  inputIndex,
  fieldIndex,
  adaptedInitialInputMaterials,
  adaptedMaterialItems,
  adaptedMaterials,
  adaptedStockMaterialBatches,
  materialItems,
  materialStockBatches,
  moreThanOneMaterial,
  isLoadingInputMaterialsData,
  inputMaterial,
  removeCard,
  dynamicInputMaterialCategory,
  isUnlocked,
  toggleUnlockField,
}) => {
  const dynamicStockBatchValue = useMemo(
    () =>
      renderDynamicSelectValues(
        values,
        inputIndex,
        fieldIndex,
        adaptedMaterials,
        adaptedMaterialItems,
        adaptedStockMaterialBatches,
        adaptedInitialInputMaterials?.[inputIndex],
        materialStockBatches!,
        materialItems!
      ),
    [
      values,
      inputIndex,
      fieldIndex,
      adaptedMaterials,
      adaptedMaterialItems,
      adaptedStockMaterialBatches,
      adaptedInitialInputMaterials,
      materialStockBatches,
      materialItems,
    ]
  );

  return (
    <div
      key={`${fieldIndex} `}
      className={classNames(
        "flex gap-y-1 gap-x-3 w-full items-center justify-between",
        {
          "!w-[32.9%] flex-col p-2 bg-gray-50 shadow-dynamicFormCard rounded-xl":
            moreThanOneMaterial,
        }
      )}
    >
      <SelectValueDropdown
        inputIndex={inputIndex}
        fieldIndex={fieldIndex}
        variantSelectValues={variantSelectValues}
        className={moreThanOneMaterial ? "w-full" : ""}
        dynamicInputMaterialCategory={dynamicInputMaterialCategory}
      />

      {isLoadingInputMaterialsData ? (
        <Loader />
      ) : (
        <DynamicInputMaterialVariant
          moreThanOneMaterial={moreThanOneMaterial}
          inputIndex={inputIndex}
          fieldIndex={fieldIndex}
          dynamicStockBatchValue={dynamicStockBatchValue}
          adaptedInitialInputMaterials={adaptedInitialInputMaterials}
        />
      )}

      <DynamicInputMaterialQuantity
        isUnlocked={isUnlocked}
        toggleUnlockField={toggleUnlockField}
        moreThanOneMaterial={moreThanOneMaterial}
        inputIndex={inputIndex}
        fieldIndex={fieldIndex}
        inputMaterial={inputMaterial}
        removeItem={removeCard}
      />
    </div>
  );
};

export { ProductionInputMaterialsForm };
