export const productCardColumnsData = {
  name: {
    title: "productCardColumns.name",
    dataIndex: "name",
    key: "name",
    width: "15%",
  },
  company: {
    title: "Company",
    key: "company",
    width: "10%",
  },
  actions: {
    title: "productCardColumns.actions",
    key: "actions",
    width: "5%",
  },
  processStep: {
    title: "Process Step",
    key: "process-step",
    width: "60%",
  },
  customer: {
    title: "Customer",
    key: "customer",
    width: "20%",
  },
};

export const processStepsColumnsData = {
  key: {
    key: "sort",
  },
  name: {
    title: "processStepsColumnsData.name",
    dataIndex: "name",
    key: "name",
    width: "95%",
  },
  customer: {
    title: "Customer",
    key: "customer",
    width: "35%",
  },
  actions: {
    title: "processStepsColumnsData.actions",
    key: "actions",
    width: "5%",
  },
};

export const productCardsInitialValues = {
  id: null,
  name: null,
  company: null,
  process_steps: [],
};
