import { Dayjs } from "dayjs";
import { ConsumptionTypes } from "shared/constants";
import { Unit } from "shared/helpers";
import {
  ConfigDynamicTrackerFields,
  MaterialCategoriesItem,
  SupplierItemWithCost,
} from "../materials/models";
import { ProcessTemplateItem } from "../productCard/models";

export const enum OrderStatuses {
  AC = "AC",
  AP = "AP",
  CO = "CO",
  DE = "DE",
  PE = "PE",
  PO = "PO",
}

export interface ProductCard {
  id: number;
  name: string;
  company: number;
  process_steps: number[];
  is_archived?: boolean;
}

export interface ExpandedBuyerItem {
  id: number;
  name: string;
  address: string;
  payment_term_net_days: number;
  inco_terms: string;
  is_supplier: boolean;
  is_buyer: boolean;
}

export type DynamicKeyValue = string | number;

export interface DynamicOrderObject {
  [key: string]: {
    [field: string]: DynamicKeyValue;
  };
}

export interface ProcessStepTrackerItem {
  id: number;
  order: number;
  process_step: {
    id: number;
    name: string;
    process_template: ProcessTemplateItem;
    dynamic_process_step_fields: DynamicOrderObject;
    input_materials: number[];
    output_materials: number[];
  };
  machine: null;
  start_datetime: null;
  end_datetime: null;
  status: string;
}

export interface OrderItem {
  id: number;
  buyer: ExpandedBuyerItem;
  po_number: string;
  wanted_delivery_datetime: string;
  production_datetime: string;
  product_card: ProductCard;
  quantity_expected: number;
  status: OrderStatuses;
  dynamic_order_fields: DynamicOrderObject;
  calc_count_process_step_trackers_pending: number;
  calc_count_process_step_trackers_in_progress: number;
  calc_count_process_step_trackers_finished: number;
  process_step_trackers: ProcessStepTrackerItem[];
  order_code?: string;
  calc_progress?: string;
  customer_order_number: string;
}

export interface OrdersResponse {
  count: number;
  next: string;
  previous: string;
  results: OrderItem[];
}

export interface OrdersParams {
  offset?: number | null;
  limit?: number | null;
  expand?: string;
  ordering?: string | null;
  po_number__icontains?: string | null;
  quantity_expected__gte?: string | number;
  quantity_expected__lte?: string | number;
  wanted_delivery_datetime__gte?: string | number;
  wanted_delivery_datetime__lte?: string | number;
  production_datetime__gte?: string | number;
  production_datetime__lte?: string | number;
  status?: string;
  buyer?: number | string;
  order_code?: number | string;
  status__in?: string[];
  include_calc_progress?: boolean;
  process_step_trackers__machine__in?: number[];
  order?: number;
  product_card__name__icontains?: string;
}

export interface GetOrderByIdParams {
  id?: number;
  expand?: string;
  order_code?: string;
}

export interface CreateOrderItem {
  buyer: number;
  po_number: string;
  wanted_delivery_datetime: string;
  production_datetime: string;
  product_card: number;
  quantity_expected: number;
  status: string;
  dynamic_order_fields: DynamicOrderObject;
  customer_order_number: string;
}

export interface UpdateOrderItem {
  id: number;
  buyer: number;
  po_number: string;
  wanted_delivery_datetime: string;
  production_datetime: string;
  product_card: number;
  quantity_expected: number;
  status: string;
  dynamic_order_fields: DynamicOrderObject;
  customer_order_number: string;
}

export interface OrderStepTrackerParams {
  id?: number;
  expand?: string;
  order__status?: string;
  process_step__process_template?: number | string;
}

export interface ConfigStepFields {
  name: string;
  type: string;
  description: string;
  choices: number | number[] | string[];
  required: boolean;
  value?: any;
}

export interface InputMaterials {
  id: number;
  materialName?: string;
  selectValue: string;
  fields: InputMaterialFields[];
}

export interface DynamicOutputMaterialObject {
  [key: string]: {
    [field: string]: DynamicKeyValue;
  };
}
export interface OutputMaterial {
  id: number;
  name: string;
  materialName?: string;
  material_category: OutputMaterialMaterialCategory;
  suppliers: number[];
  target_quantity_in_stock: number;
  numberOfMaterialItems?: number;
  dynamicData?: {
    quantity: number;
    dynamic_tracker_fields?: DynamicOutputMaterialObject;
    status: string;
  }[];
}

export interface OutputMaterialMaterialCategory {
  id: number;
  name: string;
  unit: number;
  config_dynamic_tracker_fields: ConfigDynamicTrackerFields[];
}

export interface InputMaterialCategory {
  id: number;
  name: string;
  unit: number;
  default_material_consume_variant: string;
  lock_material_consume_variant: false;
  config_dynamic_tracker_fields: ConfigDynamicTrackerFields;
  unique_code: string;
  suppliers: number[];
}

export interface DynamicOutputMaterialData {
  quantity: number;
  dynamic_tracker_fields?: DynamicOutputMaterialObject;
  status: string;
}
export interface ProductionOutputMaterial {
  id: number;
  unit?: Unit;
  numberOfMaterialItems?: number;
  default_number_of_items: number;
  dynamicData: DynamicOutputMaterialData[];
  material: {
    id: number;
    name: string;
    material_category: OutputMaterialMaterialCategory;
    suppliers: SupplierItemWithCost[];
    target_quantity_in_stock: number;
    n_packaging_items: number;
  };
}

export interface ConsumptionProportionalMaterial {
  id: number;
  name: string;
  material_category: number;
  target_quantity_in_stock: number;
  n_packaging_items: number;
}

export interface InputMaterialFields {
  variant: string;
  quantity: number;
  selectValue?: string;
}

export interface ProductionInputMaterial {
  consumptionType: ConsumptionTypes;
  consumptionFixedValue?: number | null;
  consumptionProportionalValue?: number | null;
  consumptionProportionalMaterial?: ConsumptionProportionalMaterial;
  id: number;
  material: {
    id: number;
    name: string;
    material_category: InputMaterialCategory;
    suppliers: SupplierItemWithCost[];
    target_quantity_in_stock: number;
    n_packaging_items: number;
  };
  materialName?: string;
  selectValue: string;
  fields: InputMaterialFields[];
  consumption_type: ConsumptionTypes;
  consumption_fixed_value?: number | null;
  consumption_proportional_value?: number | null;
  consumption_proportional_material?: ConsumptionProportionalMaterial;
  materialCategory?: MaterialCategoriesItem;
  calc_hide_field?: boolean;
  calcHideField?: boolean;
}

export interface OrderStepTrackerResponseItem {
  id: number;
  order: {
    id: number;
    buyer: {
      id: number;
      name: string;
      address: string;
      payment_term_net_days: number;
      inco_terms: string;
      is_supplier: false;
      is_buyer: true;
      config_dynamic_order_fields: null;
      product_cards: [];
    };
    order_code?: string;
    po_number: string;
    wanted_delivery_datetime: string;
    production_datetime: string;
    product_card: number;
    quantity_expected: number;
    status: string;
    dynamic_order_fields: null;
    process_step_trackers: number[];
  };
  process_step: {
    id: number;
    name: string;
    process_template: {
      id: number;
      name: string;
      description: string;
      config_dynamic_process_step_fields: [
        {
          title: string;
          fields: ConfigStepFields[];
        }
      ];
    };
    dynamic_process_step_fields: DynamicOrderObject;
    input_materials: ProductionInputMaterial[];
    output_materials: ProductionOutputMaterial[];
  };
  machine: number;
  start_datetime: string;
  end_datetime: string;
  status: string;
  variant: string | null;
  variantValue: number | null;
  input_materials: InputMaterials[];
  calc_default_dynamic_tracker_fields: DynamicOutputMaterialObject[] | null;
  info?: string | null;
}

export interface OrderProcessStepItem {
  id: number;
  order: {
    id: number;
    buyer: number;
    po_number: string;
    wanted_delivery_datetime: string;
    production_datetime: string;
    product_card: number;
    quantity_expected: number;
    status: string;
    dynamic_order_fields: null;
    process_step_trackers: number[];
  };
  process_step: {
    id: number;
    name: string;
    process_template: number;
    dynamic_process_step_fields: DynamicOrderObject;
    input_materials: number[];
    output_materials: number[];
  };
  machine: null | number;
  start_datetime: null | string;
  end_datetime: null | string;
  status: string;
}

export interface UpdateOrderStepTrackerItem {
  id: number;
  machine: number;
  start_datetime: string;
  end_datetime: string;
  status?: string;
  info?: string | null;
}

export interface UpdateOrderStepTrackerItemExpanded {
  id?: number;
  input_materials: InputMaterialFormik[];
  output_materials: OutputMaterialsFormik[];
}

export interface InputMaterialFormik {
  id: string | number;
  variant: string | null;
  quantity: number;
}

export interface OutputMaterialsFormik {
  material_id: number;
  items_to_produce: [
    {
      quantity: number;
      dynamic_tracker_fields: any;
      status: string;
    }
  ];
}

export interface OrdersPlansParams {
  offset?: number | null;
  limit?: number | null;
  expand?: string;
  ordering?: string | null;
}

export interface OrdersPlansResponse {
  count: number;
  next: string;
  previous: string;
  results: OrderPlan[];
}

export interface OrderPlan {
  id?: number;
  name: string;
  status: string;
  // order_plan_for_machine?: OrderMachinePlan[];
  order_plan_for_machine?: OrderPlanForMachine[];
}

export interface OrderPlanForMachine {
  id: number;
  order_plan: number;
  machine: number;
  order_process_step_tracker: {
    id: number;
    order: {
      id: number;
      buyer: number;
      po_number: string;
      wanted_delivery_datetime: string;
      production_datetime: string;
      product_card: number;
      quantity_expected: number;
      status: string;
      dynamic_order_fields: null;
      process_step_trackers: number[];
      calc_count_process_step_trackers_pending: number;
      calc_count_process_step_trackers_in_progress: number;
      calc_count_process_step_trackers_finished: number;
      calc_count_process_step_trackers: number;
    };
    process_step: {
      id: number;
      name: string;
      process_template: number;
      dynamic_process_step_fields: any[];
      input_materials: number[];
      output_materials: number[];
    };
    machine: number;
    start_datetime: string;
    end_datetime: string;
    status: string;
    calc_position_in_order: number;
  };
  start_datetime: string;
  end_datetime: string;
}

export interface OrderMachinePlan {
  id: number;
  order_plan: number;
  machine: number;
  order_process_step_tracker?: number;
  start_datetime: number | string | Dayjs;
  end_datetime: number | string | Dayjs;
}

export interface OrderPlansForMachineResponse {
  count: number;
  next: string;
  previous: string;
  results: OrderMachinePlan[];
}

export interface OrderPlansForMachineParams {
  id?: number;
  offset?: number | null;
  limit?: number | null;
  expand?: string;
  ordering?: string | null;
}

export interface CreateOrderPlanForMachine {
  order_plan?: number;
  machine?: number;
  order_process_step_tracker: number;
  start_datetime: string | Dayjs;
  end_datetime: string | Dayjs;
}

export interface MoveItemsParams {
  id: number;
  material_item_ids: number[];
}

export interface GenerateMultiplePackagingReport {
  id: number;
  material_id: number | null;
  packaging_ids: number[] | null;
}

export interface GenerateOrderProcessStepLabel {
  id: number;
}

export interface CorrectAllPackagingParams {
  id: number;
}
export interface CorrectAllPackagingResponse {
  message: string;
}

export interface MaterialItemsMassUpdate {
  material_items: number[];
  status?: string | null;
  moved_to_order?: number | null;
}
