import {
  MaterialItemSituation,
  MaterialStockItem,
  MaterialStockItemsStatus,
} from "shared/api";

export const renderMaterialItemsStatuses = (item: MaterialStockItem) => {
  if (item.status === MaterialStockItemsStatus.PRIMA) return "Prima";
  if (item.status === MaterialStockItemsStatus.WASTE) return "Waste";
  if (item.status === MaterialStockItemsStatus.INVESTIGATION)
    return "Investigation";

  return "Unknown status";
};

export const renderMaterialItemsSituations = (item: MaterialStockItem) => {
  if (item.situation === MaterialItemSituation.NORMAL) return "On Site";
  if (item.situation === MaterialItemSituation.SENT) return "Sent";
  if (item.situation === MaterialItemSituation.USED) return "Used";

  return "Unknown situations";
};

export const materialItemsSituations: Record<MaterialItemSituation, string> = {
  [MaterialItemSituation.NORMAL]: "Normal",
  [MaterialItemSituation.SENT]: "Sent",
  [MaterialItemSituation.USED]: "Used",
};

export const renderMaterialItemSituation = (situation: string) => {
  const status = materialItemsSituations[situation as MaterialItemSituation];
  return status || "Unknown Situation";
};
