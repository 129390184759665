import { Modal, notification, Select } from "antd";
import Title from "antd/es/typography/Title";
import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { adaptOrders } from "shared/adapters";
import {
  useGetAllOrdersWithNoParamsQuery,
  useMassMaterialItemsUpdatedMutation,
} from "shared/api";
import { materialItemStatuses, materialItemSituations } from "shared/constants";
import { handleRequestError, selectFiltering } from "shared/helpers";
import { Colors } from "shared/themes";

interface MassUpdateModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedIds: React.Key[];
}

const MassUpdateModal: FC<MassUpdateModalProps> = ({
  isOpen,
  onClose,
  selectedIds,
}) => {
  const { t } = useTranslation("", { keyPrefix: "itemView" });
  const [api, contextHolder] = notification.useNotification();

  const [massMaterialItemsUpdated, { isLoading: isLoadingMassUpdate }] =
    useMassMaterialItemsUpdatedMutation();

  const { data: orders, isLoading: isLoadingOrders } =
    useGetAllOrdersWithNoParamsQuery({});

  const adaptedOrders = useMemo(() => {
    return adaptOrders(orders ?? []);
  }, [orders]);

  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [selectedOrder, setSelectedOrder] = useState<number | null>(null);
  const [selectedSituation, setSelectedSituation] = useState<string | null>(
    null
  );

  const closeModalAndResetValues = () => {
    setSelectedStatus(null);
    setSelectedOrder(null);
    setSelectedSituation(null);
    onClose();
  };

  const handleMassUpdate = async () => {
    try {
      const payload = {
        material_items: selectedIds as number[],
        ...(selectedStatus && { status: selectedStatus }),
        ...(selectedOrder && { moved_to_order: selectedOrder }),
        ...(selectedSituation && { situation: selectedSituation }),
      };

      await massMaterialItemsUpdated(payload).unwrap();

      api.success({
        message: t("itemsUpdatedSuccessfully"),
      });

      closeModalAndResetValues();
    } catch (error) {
      api.error({
        message: t("error"),
        description: handleRequestError(error, t("error")),
      });
    }
  };

  const showMassUpdateConfirmation = () => {
    Modal.confirm({
      title: t("areYouSureYouWantToUpdateTheseItems"),
      width: "450px",
      onOk: () => {
        handleMassUpdate();
      },
      okButtonProps: {
        loading: isLoadingMassUpdate,
        style: { background: `${Colors.blue.primary}` },
      },
    });
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={isOpen}
        onCancel={closeModalAndResetValues}
        onOk={showMassUpdateConfirmation}
        okButtonProps={{
          style: { background: `${Colors.blue.primary}` },
        }}
      >
        <Title level={5}>{t("status")}</Title>

        <Select
          value={selectedStatus}
          placeholder={t("status")}
          onChange={(value) => setSelectedStatus(value)}
          options={materialItemStatuses}
          showSearch
          filterOption={(input, option) => selectFiltering(input, option)}
          className="w-full"
          size="large"
        />

        <Title level={5}>{t("order")}</Title>

        <Select
          value={selectedOrder}
          placeholder={t("order")}
          onChange={(value) => setSelectedOrder(value)}
          options={adaptedOrders}
          showSearch
          filterOption={(input, option) => selectFiltering(input, option)}
          loading={isLoadingOrders}
          className="w-full"
          size="large"
        />

        <Title level={5}>{t("situation")}</Title>
        <Select
          value={selectedSituation}
          placeholder={t("order")}
          onChange={(value) => setSelectedSituation(value)}
          options={materialItemSituations}
          showSearch
          filterOption={(input, option) => selectFiltering(input, option)}
          loading={isLoadingOrders}
          className="w-full"
          size="large"
        />
      </Modal>
    </>
  );
};

export { MassUpdateModal };
