import React, { FC, useEffect } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { FormSelect } from "shared/ui";
import classNames from "classnames";
import { InputMaterialCategory } from "shared/api";
import { AddDynamicOrderSteps, DropdownValues } from "shared/types";

interface SelectValueDropdownProps {
  className?: string;
  inputIndex: number;
  fieldIndex: number;
  variantSelectValues: DropdownValues[];

  dynamicInputMaterialCategory?: InputMaterialCategory;
}

const SelectValueDropdown: FC<SelectValueDropdownProps> = ({
  className,
  fieldIndex,
  inputIndex,
  variantSelectValues,
  dynamicInputMaterialCategory,
}) => {
  const { t } = useTranslation();
  const { values, handleBlur, setFieldValue } =
    useFormikContext<AddDynamicOrderSteps>();

  const selectValue =
    values.input_materials?.[inputIndex]?.fields?.[fieldIndex].selectValue;

  const fieldName = `input_materials.${inputIndex}.fields.${fieldIndex}.selectValue`;

  const variantField = `input_materials.${inputIndex}.fields.${fieldIndex}.variant`;

  useEffect(() => {
    setFieldValue(variantField, null);
  }, [selectValue]);

  const defaultMaterialCategoryValue =
    dynamicInputMaterialCategory?.default_material_consume_variant;

  // const isLockedToEdit =
  //   dynamicInputMaterialCategory?.lock_material_consume_variant;

  useEffect(() => {
    if (dynamicInputMaterialCategory) {
      setFieldValue(fieldName, defaultMaterialCategoryValue);
    }
  }, [dynamicInputMaterialCategory]);

  return (
    <div className={classNames("w-[30%]", {}, className)}>
      <FormSelect
        value={selectValue}
        placeholder={t("orders.select-variant")}
        fieldName={fieldName}
        options={variantSelectValues}
        setFieldValue={setFieldValue}
        handleBlur={handleBlur}
        // disabled={isLockedToEdit}
      />
    </div>
  );
};

export { SelectValueDropdown };
