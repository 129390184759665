import { AnyType } from "shared/helpers";
import { PackagingItem } from "../packagingApi";
import { CompanyItem } from "../suppliers";
import { OrderItem } from "../orders";

// dimensions
export interface DimensionItem {
  id: number;
  name: string;
}

export interface DimensionsResponse {
  count: number;
  next: string;
  previous: string;
  results: DimensionItem[];
}

// dimension unit
export interface DimensionUnitItem {
  id: number;
  // dimension: number;
  dimension: {
    id: number;
    name: string;
  };
  name: string;
  abbreviation: string;
}
export interface DimensionUnitsResponse {
  count: number;
  next: string;
  previous: string;
  results: DimensionUnitItem[];
}
// material category
export interface ExpandUnitItem {
  abbreviation: string;
  dimension: number;
  id: number;
  name: string;
}

export interface UnitItemWithLabel {
  label: string;
  options: {
    label: string;
    value: number;
  }[];
}

export interface MaterialCategoryFieldItem {
  name: string;
  type: string;
  description: string;
  choices: number | number[] | string[];
  unit?: number;
  required: boolean;
  hard_minimum?: number;
  soft_minimum?: number;
  soft_maximum?: number;
  hard_maximum?: number;
}

export interface MaterialCategoryTemplateDownloadTemplate {
  id: number;
}

export interface ConfigDynamicTrackerFields {
  title: string;
  fields: MaterialCategoryFieldItem[];
}

export interface MaterialCategorySupplier {
  id: number;
  order: number;
  company: CompanyItem;
}
export interface MaterialCategoriesItem {
  id?: number | null;
  name: string;
  unit: ExpandUnitItem | number;
  config_dynamic_tracker_fields?: ConfigDynamicTrackerFields[];
  unique_code?: string;
  lock_material_consume_variant: boolean;
  default_material_consume_variant: string | null;
  suppliers: number[] | MaterialCategorySupplier[] | null;
  export_col_for_serial_number: number | null;
  export_col_for_quantity: number | null;
}

export interface AllMaterialCategoriesParams {
  expand?: string;
  offset?: number | null;
  limit?: number | null;
  name__icontains?: string | null;
}

export interface MaterialCategoryByIdParams {
  id: number;
  expand?: string;
}

export interface MaterialCategoriesResponse {
  count: number;
  next: string;
  previous: string;
  results: MaterialCategoriesItem[];
}

export interface CreateMaterialCategoryParams {
  name: string;
  unit: ExpandUnitItem | number;
  unique_code: string;
  lock_material_consume_variant: boolean;
  default_material_consume_variant: string | null;
  config_dynamic_tracker_fields?: ConfigDynamicTrackerFields[];
  suppliers: number[];
  export_col_for_serial_number: number | null;
  export_col_for_quantity: number | null;
}
export interface UpdateMaterialCategoryParams {
  name: string;
  unit: ExpandUnitItem | number;
}
// material item

export interface SuppliersItem {
  id: number;
  name: string;
  address: string;
  payment_term_net_days: number;
  inco_terms: string;
  is_supplier: boolean;
  is_buyer: boolean;
  key: number;
}

export const enum CurrenciesEnum {
  USD = "USD",
  EUR = "EUR",
  SEK = "SEK",
}

export interface SupplierItemWithCost {
  key?: number;
  id: number;
  cost_per_unit_currency: CurrenciesEnum;
  cost_per_unit: string;
  order: number;
  supplier: {
    id: number;
    name: string;
    address: string;
    payment_term_net_days?: number;
    inco_terms?: string;
    is_supplier?: boolean;
    is_buyer?: boolean;
    config_dynamic_order_fields?: ConfigDynamicTrackerFields[];
    product_cards?: number[];
    evaluation_as_supplier?: number;
  };
}

export interface MaterialsItem {
  id: number;
  material_category: {
    id: number | null;
    name?: string | null;
    unit?: ExpandUnitItem | number;
  };
  target_quantity_in_stock?: number;
  calc_total_remaining_quantity?: number;
  calc_total_reserved_quantity?: number;
  calc_total_available_quantity?: number;
  calc_percentage_remaining?: number;
  calc_percentage_available?: number;
  calc_total_missing_quantity?: number;
  calc_percentage_missing?: number;
  unique_code?: string;
  name: string;
  suppliers: SupplierItemWithCost[];
  unit: ExpandUnitItem;
  config_dynamic_tracker_fields?: AnyType;
  n_packaging_items?: number;
  lock_material_consume_variant?: boolean;
  default_material_consume_variant?: string | null;
  expanded_label_name: string | null;
  last_consumed?: string | null;
}

export interface SupplierToUpdateMaterial {
  cost_per_unit_currency: string;
  cost_per_unit: number;
  supplier: number;
}

export interface UpdateMaterial {
  id: number;
  name: string;
  material_category: number;
  target_quantity_in_stock: number;
  suppliers: number[] | SupplierToUpdateMaterial[];
  n_packaging_items: number;
  expanded_label_name: string;
}

export interface CreateMaterialParams {
  material_category: number;
  name: string;
  suppliers: number[] | SupplierItemWithCost[];
  target_quantity_in_stock: number;
  n_packaging_items: number;
  expanded_label_name: string;
}

export interface MaterialsResponse {
  count: number;
  next: string;
  previous: string;
  results: MaterialsItem[];
}

export interface AllMaterialsParams {
  offset?: number | null;
  limit?: number | null;
  expand?: string;
  ordering?: string;
  name__icontains?: string | null;
  material_category?: number | string | boolean;
  id?: number | null;
  id__in?: number[];
  material_category__in?: number[];
  order?: number;
  last_consumed?: boolean;
}

// material stock batches

export enum BatchStatus {
  DELIVERED = "D",
  PENDING = "P",
  CANCELLED = "C",
  PRODUCED = "R",
}

export interface StockBatchSupplier {
  id: number;
  name: string;
  address: string;
  payment_term_net_days: number;
  inco_terms: string;
  is_supplier: boolean;
  is_buyer: boolean;
  key: number;
}

export interface MaterialStockBatchItemResponse {
  id: number;
  material: {
    id: number;
    name: string;
    material_category: {
      id: number;
      name: string;
      unit: DimensionUnitItem;
    };
    suppliers: number[] | SuppliersItem[] | [];
  };
  batch_code: string;
  is_quantity_in_tracker_table: boolean;
  quantity: number;
  delivery_date: string;
  status: BatchStatus;
  supplier?: SuppliersItem;
  calc_percentage_remaining_quantity?: number;
  calc_total_consumed_quantity?: number;
  calc_total_original_quantity?: number;
  calc_total_remaining_quantity?: number;
}

export interface MaterialStockBatchesResponse {
  count: number;
  next: string;
  previous: string;
  results: MaterialStockBatchItemResponse[];
}

export interface ProducedOrderItem {
  id: number;
  material: {
    id: number;
    name: string;
    material_category: {
      id: number;
      name: string;
      unit: {
        id: number;
        dimension: number;
        name: string;
        abbreviation: string;
      };
      // TO-DO: add type
      config_dynamic_tracker_fields: any;
      unique_code: string;
    };
    suppliers: number[];
    target_quantity_in_stock: number;
  };
  batch_code: string;
  is_quantity_in_tracker_table: boolean;
  quantity: null;
  delivery_date: string;
  status: string;
  supplier: null;
  produced_in_order_process_step_tracker: {
    id: number;
    order: number;
    process_step: number;
    machine: number;
    start_datetime: string;
    end_datetime: string;
    status: string;
  };
  calc_total_original_quantity: number;
  calc_total_consumed_quantity: number;
  calc_total_remaining_quantity: number;
  calc_percentage_remaining_quantity: number;
}

export interface ProducedOrderItemsResponse {
  count: number;
  next: string;
  previous: string;
  results: ProducedOrderItem[];
}

export interface StockMaterialsParams {
  offset?: number | null;
  limit?: number | null;
  expand?: string;
  ordering?: string | null;
  batch_code__icontains?: string | null;
  material__name?: null | string;
  delivery_date__lte?: null | string;
  delivery_date__gte?: null | string;
  material?: string | number;
  produced_in_order_process_step_tracker?: number;
  material__in?: string;
  produced_in_order_process_step_tracker__order?: number | string;
  supplier__name__icontains?: string | null;
  supplier__name?: string | null;
}

export interface DimensionUnitParams {
  expand?: string;
}

export interface StockMaterialItem {
  id: number | null;
  material: {
    id: number;
    name: string;
    material_category: {
      id: number;
      name: string;
      unit: DimensionUnitItem;
      config_dynamic_tracker_fields?: AnyType;
    };
    suppliers: number[] | SuppliersItem[] | [];
  };
  batch_code: string;
  is_quantity_in_tracker_table: boolean | null;
  quantity: number | undefined | null;
  delivery_date: string;
  status: BatchStatus;
  supplier?: null | number | SuppliersItem;
  calc_total_original_quantity?: number;
  calc_total_consumed_quantity?: number;
  calc_total_remaining_quantity?: number;
  calc_percentage_remaining_quantity?: number;
}

export interface AddStockMaterialItem {
  material: number;
  batch_code: string;
  is_quantity_in_tracker_table: boolean;
  quantity: number;
  delivery_date: string;
  status: string;
  supplier: number;
}

export interface EditStockMaterialItem {
  id?: number | null;
  material?: number;
  batch_code?: string;
  is_quantity_in_tracker_table?: boolean | null;
  quantity?: number | null;
  delivery_date?: string;
  status?: string;
  supplier?: number | null;
}

export interface EDitStockMaterialItem {
  id: number | null;
  material: number;
  materialDetails: {
    id: number;
    name: string;
    material_category: {
      id: number;
      name: string;
      unit: DimensionUnitItem;
    };
    suppliers: number[] | SuppliersItem[] | [];
  };
  batch_code: string;
  is_quantity_in_tracker_table: boolean | null;
  quantity: number | undefined;
  delivery_date: string;
  status: BatchStatus;
  supplier?: null | number | SuppliersItem;
  stockMaterial?: ProducedOrderItem | null;
}

export interface MaterialItemsParams {
  material_stock_batch?: number | null;
  expand?: string;
  offset?: number | null;
  limit?: number | null;
  material?: number | null;
  serial_number__icontains?: string;
  ordering?: string;
  status__in?: string | string[];
  id?: number | null;
  material_stock_batch__in?: number[];
  timestamp__gte?: string | null;
  timestamp__lte?: string | null;
  material_stock_batch__material__material_category?: number;
  material_stock_batch__material__name__icontains?: string;
  situation?: string | string[];
}

export enum MaterialStockItemsStatus {
  PRIMA = "P",
  WASTE = "W",
  INVESTIGATION = "I",
}

export enum MaterialSituation {
  NORMAL = "N",
  SENT = "SE",
  US = "Used",
}

export interface ExpandedOrderProcessStepTracker {
  id: number;
  order: number | OrderItem;
  process_step: number;
  machine: {
    id: number;
    name: string;
    process_template: number;
  };
  start_datetime: string;
  end_datetime: string;
  status: string;
  calc_position_in_order: number;
}

export interface OrderProductionSet {
  id: number;
  set_number: number;
  sub_set_number: number;
  order_process_step_tracker: number | ExpandedOrderProcessStepTracker;
}

export const enum MaterialItemSituation {
  NORMAL = "N",
  SENT = "SE",
  USED = "US",
}

export interface MaterialStockItem {
  id: number;
  material_stock_batch: {
    id: number;
    material: {
      id: number;
      name: string;
      material_category: {
        id: number;
        name: string;
        unit: number;
        config_dynamic_tracker_fields: AnyType;
      };
      suppliers: number[] | [];
    };
    batch_code: string;
    is_quantity_in_tracker_table: boolean;
    quantity: null | number;
    delivery_date: string;
    status: string;
    supplier: number;
  };
  serial_number: string;
  quantity: number;
  dynamic_tracker_fields: AnyType;
  calc_total_original_quantity?: number;
  calc_total_consumed_quantity?: number;
  calc_total_remaining_quantity?: number;
  calc_percentage_remaining_quantity?: number;
  status: MaterialStockItemsStatus;
  packaging: number | PackagingItem;
  timestamp?: string | null;
  order_production_set_position?: number;
  production_number?: number;
  order_production_set?: OrderProductionSet;
  calc_is_moved?: boolean;
  situation: MaterialItemSituation | null;
  moved_to_order?: {
    id: number;
    order_code: string;
  };
}

export interface AllMaterialItemsParams {
  expand?: string;
  material_stock_batch__material__in?: string;
  limit?: number | null;
  order_production_set?: number;
  order?: number;
  material_stock_batch__material__material_category?: number;
}

export interface MaterialItemsWidgetResponse {
  results: MaterialStockItem[];
  count: number;
}

export interface MaterialItemsForProductionParams {
  material?: number;
  order_production_set__order_process_step_tracker?: number;
  ordering?: string;
  expand?: string;
  timestamp__lte?: string;
  timestamp__gte?: string;
  serial_number__icontains?: string;
  order_production_set__set_number?: number;
  order_production_set_position?: number;
  moved_to_order?: number;
  order?: number;
  status?: string;
  situation?: string;
  produced_in_order?: number;
}

export interface MaterialItemsResponse {
  count: number;
  next: string;
  previous: string;
  results: MaterialStockItem[];
}

export interface CreateStockMaterialItem {
  status: string;
  material_stock_batch: number;
  serial_number: string;
  quantity: number;
  dynamic_tracker_fields: AnyType;
  situation: MaterialItemSituation;
}

export interface UpdateStockMaterialItem {
  id: number;
  status: string;
  material_stock_batch: number;
  serial_number: string;
  quantity: number;
  dynamic_tracker_fields: AnyType;
  situation: string;
}

export interface MaterialReservationParams {
  offset: number | null;
  limit: number | null;
  expand?: string;
  ordering?: string;
  material?: number | null;
}

export interface MaterialsReservationResponse {
  count: number;
  next: string;
  previous: string;
  results: MaterialReservationItem[];
}

export interface MaterialReservationItem {
  id: number;
  material: {
    id: number;
    name: string;
    material_category: number;
    suppliers: [] | number[];
  };
  reservation_date: string;
  quantity: number;
}

export interface MaterialReservationItemWithDetails {
  id: number;
  material: {
    id: number;
    name: string;
    material_category: {
      id: number;
      name: string;
      unit: {
        id: number;
        dimension: number;
        name: string;
        abbreviation: string;
      };
    };
    suppliers: [] | number[];
  };
  reservation_date: string;
  quantity: number;
}

export interface CreateMaterialReservationItem {
  material: number;
  reservation_date: string;
  quantity: number;
}

export interface EditMaterialReservationItem {
  id: number;
  material: number;
  reservation_date: string;
  quantity: number;
}

export interface MaterialConsumedParams {
  offset?: number | null;
  limit?: number | null;
  expand?: string;
  ordering?: string;
  material?: number | null;
  status__in?: string | string[];
  last_consumed?: boolean;
}

export interface ConsumedMaterial {
  id: number;
  name: string;
  material_category:
    | {
        id: number;
        name: string;
        unit: {
          id: number;
          dimension: number;
          name: string;
          abbreviation: string;
        };
      }
    | number;
  suppliers: number[] | [];
}

export interface ConsumedMaterialStockBatch {
  id: number;
  material: ConsumedMaterial;
  batch_code: string;
  is_quantity_in_tracker_table: boolean;
  quantity: number;
  delivery_date: string;
  status: string;
  supplier: number;
}

export interface ConsumedMaterialItem {
  id: number;
  material_stock_batch: ConsumedMaterialStockBatch;
  serial_number: string;
  quantity: number;
  dynamic_tracker_fields: any;
}

interface ProductCard {
  id: number;
  name: string;
  process_steps: number[];
}

interface Order {
  id: number;
  buyer: number;
  order_code: string;
  po_number: string;
  customer_order_number: string;
  wanted_delivery_datetime: string;
  production_datetime: string;
  product_card: ProductCard;
  quantity_expected: number | null;
  status: string;
  dynamic_order_fields: Record<string, any>;
  process_step_trackers: number[];
  calc_progress: number | null;
}

interface OrderProcessStepTracker {
  id: number;
  set_number: number;
  order_process_step_tracker: {
    id: number;
    order: Order;
    process_step: number;
    machine: string | null;
    start_datetime: string;
    end_datetime: string;
    status: string;
    calc_position_in_order: number;
    info: string | null;
  };
  sub_set_number: number;
}

export interface MaterialConsumedItem {
  id: number;
  consumed_datetime: string;
  quantity: number;
  order_process_step_tracker: number;
  material: ConsumedMaterial | null;
  material_stock_batch: ConsumedMaterialStockBatch | null;
  material_item: ConsumedMaterialItem | null;
  reason: string;
  status: string;
  order_production_set: any;
  key?: number;
}

export interface MaterialConsumedItemExpand {
  id: number;
  consumed_datetime: string;
  quantity: number;
  order_process_step_tracker: number;
  material: ConsumedMaterial | null;
  material_stock_batch: ConsumedMaterialStockBatch | null;
  material_item: ConsumedMaterialItem | null;
  reason: string;
  status: string;
  order_production_set: OrderProcessStepTracker;
  key?: number;
}

export interface AllMaterialConsumedParams {
  expand?: string;
}

export interface MaterialConsumedItemWithDetails {
  id: number;
  consumed_datetime: string;
  quantity: number;
  order_process_step_tracker: number;
  material: {
    id: number;
    name: string;
    material_category: {
      id: number;
      name: string;
      unit: {
        id: number;
        dimension: number;
        name: string;
        abbreviation: string;
      };
    };
    suppliers: number[] | [];
  };
  material_stock_batch: {
    id: number;
    material: {
      id: number;
      name: string;
      material_category: {
        id: number;
        name: string;
        unit: {
          id: number;
          dimension: number;
          name: string;
          abbreviation: string;
        };
      };
      suppliers: number[] | [];
    };
  };
  material_item: {
    id: number;
    material_stock_batch: {
      id: number;
      material: {
        id: number;
        name: string;
        material_category: {
          id: number;
          name: string;
          unit: {
            id: number;
            dimension: number;
            name: string;
            abbreviation: string;
          };
        };
        suppliers: number[] | [];
      };
    };
  };
  reason: string;
  status: string;
}

export interface MaterialConsumedItemNotExpanded {
  id: number | null;
  consumed_datetime: string | null;
  quantity: number | null;
  order_process_step_tracker: null;
  material?: number | null;
  material_stock_batch?: number | null;
  material_item?: number | null;
  reason: string | null;
  status: string | null;
}

export interface MaterialsConsumedResponse {
  count: number;
  next: string;
  previous: string;
  results: MaterialConsumedItem[];
}

export interface CreateMaterialConsumed {
  consumed_datetime: string;
  quantity: number;
  order_process_step_tracker: null;
  material: number | null;
  material_stock_batch: number | null;
  material_item: number | null;
  reason: string;
  status: string;
}

export interface UpdateMaterialConsumed {
  id?: number | null;
  consumed_datetime: string;
  quantity: number;
  order_process_step_tracker: null;
  material: number | null;
  material_stock_batch: number | null;
  material_item: number | null;
  reason: string;
  status: string;
}

export interface MaterialDetailsParams {
  id?: number;
  offset?: number | null;
  limit?: number | null;
  expand?: string;
  ordering?: string;
}

export interface MaterialDetailsItem {
  material_stock_batch: number;
  material_reservation: number;
  material_consumed: number;
  datetime: string;
  quantity_available: number;
  quantity_reserved: number;
  targetQuantity?: number;
}

export interface MaterialDetailsResponse {
  count: number;
  next: string;
  previous: string;
  results: MaterialDetailsItem[];
}

export interface GenerateMaterialItemReport {
  id: number;
  material_stock_batch: number;
  packaging: number;
  serial_number: string;
  quantity: number;
  dynamic_tracker_fields: ConfigDynamicTrackerFields;
  status: string;
  order_production_set_position: number;
  production_number: number;
}

export interface UploadExcelFileParams {
  formData: FormData;
}

// Delete after Andreu tests
export interface MaterialItemReport {
  id: number;
  material_stock_batch: number;
  packaging: number;
  serial_number: string;
  quantity: number;
  dynamic_tracker_fields: ConfigDynamicTrackerFields;
  status: string;
  order_production_set_position: number;
  production_number: number;
}

export interface PackageReport {
  id: number;
  name: string;
  material: number;
  order: number;
}

export interface OrderProductionSetParams {
  id: number;
  expand?: string;
}

export interface OrderProductionSetResponse {
  id: number;
  set_number: number;
  sub_set_number: number;
  order_process_step_tracker: number;
  materials_consumed: MaterialConsumedItem[];
}

export interface MaterialConsumedFromOrderProductionSet {
  consumed_datetime: string;
  quantity: number;
  order_production_set: number | null;
  material?: number;
  material_stock_batch?: number;
  material_item?: number;
  reason?: string;
  status?: string;
}
export interface UpdateProductionOrderSetParams {
  id: number;
  set_number: number;
  sub_set_number: number;
  order_process_step_tracker: number;
  materials_consumed: MaterialConsumedFromOrderProductionSet[];
}
