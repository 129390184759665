import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table, notification } from "antd";
import { useFormikContext } from "formik";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import {
  useConfirmDeleteModal,
  useDebouncedTableFilter,
  useGetCompaniesColumns,
  useModal,
  usePagination,
  useTableSorting,
  useOrdersNavigation,
} from "shared/hooks";
import {
  handleRequestError,
  renderCompanyRole,
  updateChildCompaniesToSetNewInformationField,
} from "shared/helpers";
import {
  useDeleteCompanyMutation,
  useGetAllCompaniesWithParamsQuery,
  CompanyItem,
} from "shared/api";
import { AddCompanyValues } from "shared/types";
import { RenderClearFiltersButton } from "shared/ui";
import { ChildrenCompaniesModal } from "./ChildrenCompaniesModal";

interface CompaniesTableProps {
  showEditCompanyModal: () => void;
}

const CompaniesTable: FC<CompaniesTableProps> = ({ showEditCompanyModal }) => {
  const { t } = useTranslation();
  const { setValues } = useFormikContext<AddCompanyValues>();

  const pagination = usePagination();
  const { offset, limit, pageSize, currentPage, setCurrentPage, setPageSize } =
    pagination;

  const { ordering, changeSort } = useTableSorting();

  const {
    debouncedValue: debouncedNameValue,
    handleFilterChange: handleNameValue,
    value: nameValue,
    clearFilterValue: clearNameValue,
  } = useDebouncedTableFilter("", 400);

  const {
    debouncedValue: debouncedPaymentTermsValue,
    handleFilterChange: handlePaymentTermsValue,
    value: paymentTermsValue,
    clearFilterValue: clearPaymentTermsValue,
  } = useDebouncedTableFilter("", 400);

  const {
    debouncedValue: debouncedEvaluationAsSupplierGte,
    handleFilterChange: handleEvaluationAsSupplierGte,
    value: evaluationAsSupplierGte,
    clearFilterValue: clearEvaluationAsSupplierGte,
  } = useDebouncedTableFilter("", 400);

  const {
    debouncedValue: debouncedEvaluationAsSupplierLte,
    handleFilterChange: handleEvaluationAsSupplierLte,
    value: evaluationAsSupplierLte,
    clearFilterValue: clearEvaluationAsSupplierLte,
  } = useDebouncedTableFilter("", 400);

  const {
    debouncedValue: debouncedIncoTermsValue,
    handleFilterChange: handleIncoTermsValue,
    value: incoTermsValue,
    clearFilterValue: clearIncoTermsValue,
  } = useDebouncedTableFilter("", 400);

  const [selectedCustomerSupplier, setSelectedCustomerSupplier] = useState<
    string[]
  >([]);

  const [selectCategoriesSupplier, setSelectCategoriesSupplier] = useState<
    string | number
  >("");

  const {
    data: companies,
    isLoading: isLoadingCompanies,
    isFetching: isFetchingCompanies,
  } = useGetAllCompaniesWithParamsQuery({
    offset,
    limit,
    ordering,
    name__icontains: debouncedNameValue,
    payment_term_net_days: debouncedPaymentTermsValue,
    evaluation_as_supplier__gte: debouncedEvaluationAsSupplierGte,
    evaluation_as_supplier__lte: debouncedEvaluationAsSupplierLte,
    inco_terms__icontains: debouncedIncoTermsValue,
    is_supplier: selectedCustomerSupplier.includes("is_supplier")
      ? true
      : undefined,
    is_buyer: selectedCustomerSupplier.includes("is_buyer") ? true : undefined,
    expand: "categories_supplied.material_category.unit,child_companies",
    category_supplied__material_category: selectCategoriesSupplier,
  });

  const { handleSearchOrder } = useOrdersNavigation();
  const handleSearchOrdersClick = (companyId: number) => {
    handleSearchOrder(companyId);
  };

  const [parentToShowChildren, setParentToShowChildren] =
    useState<null | CompanyItem>(null);

  const handleCustomerSupplierChange = (
    selectedValues: CheckboxValueType[]
  ) => {
    setSelectedCustomerSupplier(selectedValues as string[]);
  };

  const handleCategoriesSupplierChange = (value: string | number) => {
    setSelectCategoriesSupplier(value);
  };

  const categoriesSuppliedData = useMemo(() => {
    const allCategories =
      companies?.results
        .flatMap((company) =>
          company.categories_supplied?.map((category) =>
            typeof category.material_category === "object"
              ? category.material_category
              : null
          )
        )
        .filter(Boolean) || [];

    return Array.from(
      new Map(
        allCategories
          .filter((category) => category?.id != null)
          .map((category) => [category!.id, category])
      ).values()
    ).map((category) => ({
      label: category!.name,
      value: category!.id!,
    }));
  }, [companies]);

  const clearCustomerSupplierFilter = () => {
    setSelectedCustomerSupplier([]);
  };

  const handleSetParentToShowChildren = (record: CompanyItem | null) => {
    setParentToShowChildren(record);
  };

  const [isVisibleChildrenCompanies, showChildCompanies, hideChildCompanies] =
    useModal();

  const handleCompanyWithChildrenModal = (record: CompanyItem) => {
    showChildCompanies();
    handleSetParentToShowChildren(record);
  };

  const hideChildModalAndClearCompany = () => {
    hideChildCompanies();
    handleSetParentToShowChildren(null);
  };

  // Added this useEffect to update information field in ChildrenCompaniesModal after successful PUT request
  useEffect(() => {
    if (companies) {
      updateChildCompaniesToSetNewInformationField(
        companies,
        parentToShowChildren,
        handleSetParentToShowChildren
      );
    }
  }, [companies]);

  const [deleteCompany] = useDeleteCompanyMutation();
  const [api, contextHolder] = notification.useNotification();

  const deleteCompanyHandler = async (record: CompanyItem) => {
    try {
      await deleteCompany(record).unwrap();
      api.success({
        message: t("companies.success-title"),
        description: t("companies.success-delete-msg"),
      });
    } catch (error) {
      api.error({
        message: t("users-page.error-title"),
        description: handleRequestError(error, t("companies.error")),
      });
    }
  };

  const showCompanyDeleteModal = useConfirmDeleteModal(
    t("companies.delete-alert-msg"),
    deleteCompanyHandler
  );

  const editCompany = (record: CompanyItem) => {
    showEditCompanyModal();
    setValues({
      id: record.id,
      name: record.name,
      address: record.address,
      inco_terms: record.inco_terms,
      is_buyer: record.is_buyer,
      is_supplier: record.is_supplier,
      payment_term_net_days: record.payment_term_net_days,
      sections: record?.config_dynamic_order_fields ?? [],
      product_cards: record.product_cards!,
      image: record?.image,
      evaluation_as_supplier: record?.evaluation_as_supplier,
      categories_supplied: record?.categories_supplied,
      parent_company: record?.parent_company!,
      child_companies: record?.child_companies! as CompanyItem[],
      vat_number: record?.vat_number,
      email: record?.email,
      phone: record?.phone,
      contact_name: record?.contact_name,
      information: record?.information,
    });
  };

  const isVisibleClearFiltersButton = !!nameValue;

  const clearAllFilters = () => {
    clearNameValue();
    clearPaymentTermsValue();
    clearEvaluationAsSupplierGte();
    clearEvaluationAsSupplierLte();
    clearIncoTermsValue();
    clearCustomerSupplierFilter();
    handleCategoriesSupplierChange("");
  };

  const columns = useGetCompaniesColumns({
    t,
    changeSort,
    editCompany,
    renderCompanyRole,
    showCompanyDeleteModal,
    handleCompanyWithChildrenModal,
    nameValue,
    handleNameValue,
    clearNameValue,
    paymentTermsValue,
    handlePaymentTermsValue,
    clearPaymentTermsValue,
    evaluationAsSupplierGte,
    evaluationAsSupplierLte,
    handleEvaluationAsSupplierGte,
    handleEvaluationAsSupplierLte,
    clearEvaluationAsSupplierGte,
    clearEvaluationAsSupplierLte,
    handleIncoTermsValue,
    incoTermsValue,
    clearIncoTermsValue,
    handleCustomerSupplierChange,
    selectedCustomerSupplier,
    selectCategoriesSupplier,
    handleCategoriesSupplierChange,
    categoriesSuppliedData,
    handleSearchOrdersClick,
  });

  return (
    <>
      {contextHolder}

      {isVisibleClearFiltersButton && (
        <RenderClearFiltersButton onClick={clearAllFilters} />
      )}

      <Table
        loading={isLoadingCompanies || isFetchingCompanies}
        columns={columns}
        dataSource={companies?.results}
        rowKey={(obj) => obj.id!}
        pagination={{
          pageSize,
          showSizeChanger: true,
          current: currentPage,
          onShowSizeChange(page, pageSize) {
            setPageSize(pageSize);
            setCurrentPage(page);
          },
          onChange(page) {
            setCurrentPage(page);
          },
          total: companies?.count,
        }}
      />

      <ChildrenCompaniesModal
        isOpen={isVisibleChildrenCompanies}
        onClose={hideChildModalAndClearCompany}
        parentToShowChildren={parentToShowChildren}
      />
    </>
  );
};

export { CompaniesTable };
