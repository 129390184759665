import React, { FC, useMemo } from "react";
import { Table, notification } from "antd";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import {
  useConfirmDeleteModal,
  useDebouncedTableFilter,
  useGetProductCardsTableColumns,
  usePagination,
  useTableSorting,
} from "shared/hooks";
import { handleRequestError } from "shared/helpers";
import {
  useCloneProductCardMutation,
  useDeleteProductCardMutation,
  useGetAllProductCardsQuery,
  ProductCardItem,
  ProductCard,
  useArchiveProductCardMutation,
} from "shared/api";
import { RenderClearFiltersButton } from "shared/ui";
import { EditCartItem } from "shared/types";
import { AddProductCardValues } from "../ProductCards";

interface ProductCardTableProps {
  showEditProductCardModal: () => void;
  isLoadingProcessStepsOrFetching: boolean;
}

const ProductCardTable: FC<ProductCardTableProps> = ({
  showEditProductCardModal,
  isLoadingProcessStepsOrFetching,
}) => {
  const { t } = useTranslation();

  const pagination = usePagination();
  const { offset, limit, pageSize, currentPage, setCurrentPage, setPageSize } =
    pagination;

  const { ordering, changeSort } = useTableSorting();

  const {
    debouncedValue: debouncedNameContains,
    handleFilterChange: handleNameContainsValue,
    value: nameContainsValue,
    clearFilterValue: clearNameContainsValue,
  } = useDebouncedTableFilter("", 400);

  const {
    debouncedValue: debouncedProcessStepContains,
    handleFilterChange: handleProcessStepValue,
    value: processStepValue,
    clearFilterValue: clearProcessStepContainsValue,
  } = useDebouncedTableFilter("", 400);

  const isVisibleCleanFiltersButton =
    !!debouncedNameContains || !!debouncedProcessStepContains;

  const clearAllFilters = () => {
    clearNameContainsValue();
    clearProcessStepContainsValue();
  };

  const {
    data: productCards,
    isLoading: isLoadingProcessTypes,
    isFetching: isFetchingProcessTypes,
  } = useGetAllProductCardsQuery({
    offset,
    limit,
    ordering,
    name__icontains: debouncedNameContains,
    process_step__name__iregex: debouncedProcessStepContains,
    expand: "company,process_steps",
  });

  const { setValues } = useFormikContext<AddProductCardValues>();

  const [deleteProductCard] = useDeleteProductCardMutation();
  const [cloneProductCard] = useCloneProductCardMutation();
  const [archiveProductCard] = useArchiveProductCardMutation();
  const [api, contextHolder] = notification.useNotification();

  const deleteProductCardHandler = async (record: ProductCardItem) => {
    try {
      await deleteProductCard(record).unwrap();
      api.success({
        message: t("product-card.success-title"),
        description: t("product-card.success-delete-msg"),
      });
    } catch (error) {
      const description = handleRequestError(error, t("product-card.error"));

      api.error({
        message: t("product-card.error"),
        description,
      });
    }
  };

  const showDeleteProductCardModal = useConfirmDeleteModal(
    t("product-card.delete-alert-msg"),
    deleteProductCardHandler
  );

  const editProductCarHandler = (record: EditCartItem) => {
    showEditProductCardModal();
    setValues({
      id: record?.id,
      name: record?.name,
      process_steps: record.process_steps,
    });
  };

  const handleProductCardClone = async (card: ProductCard) => {
    try {
      const response = await cloneProductCard({
        id: card.id,
        name: card.name,
        process_steps: card.process_steps,
      }).unwrap();

      if (response) {
        setValues({ ...response });
        showEditProductCardModal();
      }

      api.success({
        message: t("product-card.success-title"),
        description: t("product-card.clone-success"),
      });
    } catch (error) {
      api.error({
        message: t("product-card.error"),
        description: handleRequestError(error, t("product-card.error")),
      });
    }
  };

  const handleProductCardArchive = async (card: ProductCard) => {
    const newArchiveValue = !card.is_archived;
    try {
      await archiveProductCard({
        id: card.id,
        is_archived: newArchiveValue,
      }).unwrap();
      api.success({
        message: t("product-card.success-title"),
        description: t("product-card.archive-success"),
      });
    } catch (error) {
      api.error({
        message: t("product-card.error"),
        description: handleRequestError(error, t("product-card.error")),
      });
    }
  };

  const processStepsData = useMemo(() => {
    const allProcessSteps =
      productCards?.results.flatMap((productCard) =>
        Array.isArray(productCard.process_steps)
          ? productCard.process_steps.map((step: any) =>
              typeof step === "object" ? step : null
            )
          : []
      ) || [];

    return Array.from(
      new Map(
        allProcessSteps
          .filter((step) => step?.id)
          .map((step) => [step.id, step])
      ).values()
    ).map((step) => ({
      label: step.name,
      value: step.id,
    }));
  }, [productCards]);

  const customerNames = useMemo(() => {
    const allCustomerNames =
      productCards?.results.flatMap((productCard) =>
        Array.isArray(productCard.process_steps)
          ? productCard.process_steps
              .filter(
                (step: any) =>
                  step?.dynamic_process_step_fields?.Misc?.end_customer_name
              )
              .map((step: any) => ({
                label: step.dynamic_process_step_fields.Misc.end_customer_name,
                value: step.id,
              }))
          : []
      ) || [];

    return Array.from(
      new Map(
        allCustomerNames.map((customer) => [customer.label, customer])
      ).values()
    );
  }, [productCards]);

  const columns = useGetProductCardsTableColumns({
    t,
    changeSort,
    editProductCarHandler,
    handleProductCardClone,
    showDeleteProductCardModal,
    nameContainsValue,
    handleNameContainsValue,
    clearNameContainsValue,
    handleProductCardArchive,
    processStepsData,
    handleProcessStepValue,
    processStepValue,
    clearProcessStepContainsValue,
    customerNames,
  });

  return (
    <>
      {contextHolder}

      {isVisibleCleanFiltersButton && (
        <RenderClearFiltersButton onClick={clearAllFilters} />
      )}

      <Table
        loading={
          isLoadingProcessTypes ||
          isFetchingProcessTypes ||
          isLoadingProcessStepsOrFetching
        }
        columns={columns}
        dataSource={productCards?.results}
        rowKey={(obj) => obj.id!}
        onRow={(record: ProductCardItem) => ({
          style: {
            backgroundColor: record.is_archived ? "grey" : "transparent",
            color: record.is_archived ? "white" : "inherit",
          },
        })}
        pagination={{
          pageSize,
          showSizeChanger: true,
          current: currentPage,
          onShowSizeChange(page, pageSize) {
            setPageSize(pageSize);
            setCurrentPage(page);
          },
          onChange(page) {
            setCurrentPage(page);
          },
          total: productCards?.count,
        }}
      />
    </>
  );
};

export { ProductCardTable };
