import { Button, Modal, UploadFile, notification } from "antd";
import React, { FC, useState } from "react";
import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { DynamicForm, FormInput, FormSelect } from "shared/ui";
import { Colors } from "shared/themes";
import {
  getSupplierStatus,
  handleRequestError,
  setCompanyCustomerValue,
  setCompanySupplierValue,
} from "shared/helpers";
import {
  useUpdateCompanyMutation,
  UnitItemWithLabel,
  MaterialCategoriesItem,
  useAddCompanyImageMutation,
  ProductCardItem,
} from "shared/api";
import { AddProductCardModal, ProductCardTable } from "pages/Companies";
import { useCompanyImageUpload, useCompanyRole, useModal } from "shared/hooks";
import { adaptMaterialCategoryForUpdate } from "shared/adapters";
import { AdaptedValues, AddCompanyValues } from "shared/types";
import {
  isSupplierOrBuyerSelectValues,
  ModalSizeValue,
} from "shared/constants";
import { CompanyLogo } from "./CompanyLogo";
import { CategoriesSuppliedDynamicForm } from "./CategoriesSuppliedDynamicForm";

interface EditCompanyModalProps {
  isOpenModal: boolean;
  adaptedDimensionUnits: UnitItemWithLabel[];
  adaptedProductCard: AdaptedValues[];
  productCards: ProductCardItem[] | undefined;
  hideEditCompanyModal: () => void;
  adaptedMaterialCategories: AdaptedValues[];
  materialCategories?: MaterialCategoriesItem[];
  documentList: UploadFile[];
  handleDocumentList: (files: UploadFile[]) => void;
  parentCompanies: AdaptedValues[];
  isLoadingProductCards?: boolean;
  handleProductCardValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  productCardValue: string;
  clearProductCardValue: () => void;
}

const EditCompanyModal: FC<EditCompanyModalProps> = ({
  isOpenModal,
  adaptedDimensionUnits,
  adaptedProductCard,
  productCards,
  hideEditCompanyModal,
  adaptedMaterialCategories,
  materialCategories,
  documentList,
  handleDocumentList,
  parentCompanies,
  isLoadingProductCards,
  handleProductCardValue,
  productCardValue,
  clearProductCardValue,
}) => {
  const {
    values,
    resetForm,
    handleBlur,
    handleChange,
    isValid,
    setFieldValue,
    errors,
  } = useFormikContext<AddCompanyValues>();

  const { isCustomer, isSupplier } = getSupplierStatus(
    values?.is_supplier as string
  );

  useCompanyRole({ values, setFieldValue });

  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const [updateCompany] = useUpdateCompanyMutation();

  const [
    visibleProductCardModal,
    showProductCardsModal,
    hideProductCardsModal,
  ] = useModal();

  const [reorderingIds, setReorderingIds] = useState<number[]>([]);

  const closeModal = () => {
    hideEditCompanyModal();
    resetForm();
    handleDocumentList([]);
  };

  const updateCompanyRequest = async (ids: number[]) => {
    try {
      await updateCompany({
        id: values.id!,
        name: values.name,
        address: values.address,
        payment_term_net_days: values.payment_term_net_days!,
        inco_terms: values.inco_terms,
        is_supplier: setCompanySupplierValue(values),
        is_buyer: setCompanyCustomerValue(values),
        config_dynamic_order_fields: values.sections!,
        product_cards: isSupplier ? [] : ids,
        evaluation_as_supplier: isCustomer
          ? null
          : values.evaluation_as_supplier!,
        categories_supplied: adaptMaterialCategoryForUpdate(values),
        parent_company: values.parent_company,
        child_companies: values.child_companies?.map((company) => company.id),
        vat_number: values.vat_number?.length ? values.vat_number : null,
        email: values.email?.length ? values?.email : null,
        phone: values.phone?.length ? values.phone : null,
        contact_name: values.contact_name?.length ? values.contact_name : null,
        information: values.information?.length ? values.information : null,
      }).unwrap();
      api.success({
        message: t("companies.success-title"),
        description: t("companies.success-update-msg"),
      });
      hideEditCompanyModal();
    } catch (error) {
      const description = handleRequestError(
        error,
        t("users-page.error-title")
      );

      api.error({
        message: t("users-page.error-title"),
        description,
      });
    }
  };

  const [addCompanyImage] = useAddCompanyImageMutation();

  const {
    uploadedFile,
    previewLogoImage,
    uploadCompanyImageProps,
    closeModalAndClearUploadedImage,
  } = useCompanyImageUpload({
    documentList,
    handleDocumentList,
    closeModal,
  });

  const uploadCompanyImage = async () => {
    if (uploadedFile) {
      const formData = new FormData();
      formData.append("file", uploadedFile);

      try {
        await addCompanyImage({
          id: values.id!,
          image: formData,
        }).unwrap();
      } catch (error) {
        api.error({
          message: t("users-page.error-title"),
          description: handleRequestError(error, t("users-page.error-title")),
        });
      } finally {
        closeModalAndClearUploadedImage();
      }
    }
  };

  const updateCompanyHandler = () => {
    if (uploadedFile) {
      uploadCompanyImage();
    }

    updateCompanyRequest(reorderingIds);
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={t("companies.edit-company-title")}
        open={isOpenModal}
        width={
          values.sections.length
            ? ModalSizeValue.XL_MEDIUM
            : ModalSizeValue.SECONDARY
        }
        okButtonProps={{
          disabled: !isValid,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
        onCancel={closeModal}
        onOk={updateCompanyHandler}
      >
        <CompanyLogo
          documentList={documentList}
          uploadCompanyImageProps={uploadCompanyImageProps}
          previewLogoImage={previewLogoImage}
        />

        <Title level={5}>{t("companies.parentCompany")}</Title>
        <FormSelect
          value={values?.parent_company}
          placeholder={t("companies.parent-company-placeholder")}
          fieldName="parent_company"
          options={parentCompanies}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          showSearch
        />

        <Title level={5} className="mb-1.25">
          {t("companies.name")}
        </Title>

        <FormInput
          value={values.name}
          fieldName="name"
          placeholder={t("companies.name-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.name}
          isError={!!errors.name}
        />

        <Title level={5} className="mb-1.25">
          {t("companies.email")}
        </Title>

        <FormInput
          value={values.email}
          fieldName="email"
          placeholder={t("companies.email-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.email}
          isError={!!errors.email}
        />

        <Title level={5} className="mb-1.25">
          {t("companies.phone")}
        </Title>

        <FormInput
          value={values.phone}
          fieldName="phone"
          placeholder={t("companies.phone-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.phone}
          isError={!!errors.phone}
        />

        <Title level={5} className="mb-1.25">
          {t("companies.vat-number")}
        </Title>

        <FormInput
          value={values.vat_number}
          fieldName="vat_number"
          placeholder={t("companies.vat-number-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.vat_number}
          isError={!!errors.vat_number}
        />

        <Title level={5} className="mb-1.25">
          {t("companies.address")}
        </Title>

        <FormInput
          value={values.address}
          fieldName="address"
          placeholder={t("companies.address-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.address}
          isError={!!errors.address}
        />

        <Title level={5} className="mb-1.25">
          {t("companies.payment-term")}
        </Title>

        <FormInput
          value={values.payment_term_net_days!}
          fieldName="payment_term_net_days"
          placeholder={t("companies.payment-term-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.payment_term_net_days}
          isError={!!errors.payment_term_net_days}
          numberType
        />

        <Title level={5} className="mb-1.25">
          {t("companies.inco-terms")}
        </Title>

        <FormInput
          value={values.inco_terms.toUpperCase()}
          fieldName="inco_terms"
          placeholder={t("companies.inco-terms-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.inco_terms}
          isError={!!errors.inco_terms}
        />

        <Title level={5}>
          {`${t("companies.is-supplier")} / ${t("companies.is-customer")}`}
        </Title>

        <FormSelect
          value={values.is_supplier || null}
          placeholder={t("companies.is-supplier-placeholder")}
          fieldName="is_supplier"
          options={isSupplierOrBuyerSelectValues}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.is_supplier?.toString()}
          isError={!!errors.is_supplier}
        />

        <Title level={5} className="mb-1.25">
          {t("companies.evaluation_as_supplier")}
        </Title>

        <FormInput
          value={isCustomer ? null : values.evaluation_as_supplier!}
          fieldName="evaluation_as_supplier"
          placeholder={t("companies.evaluation_as_supplier")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.evaluation_as_supplier}
          isError={!!errors.evaluation_as_supplier}
          numberType
          disabled={isCustomer}
        />

        <Title level={5} className="mb-1.25">
          {t("companies.contact-name")}
        </Title>

        <FormInput
          value={values.contact_name}
          fieldName="contact_name"
          placeholder={t("companies.contact-name-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />

        <Title level={5} className="mb-1.25">
          {t("companies.information")}
        </Title>

        <FormInput
          value={values.information}
          fieldName="information"
          placeholder={t("companies.information-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />

        <CategoriesSuppliedDynamicForm
          adaptedMaterialCategories={adaptedMaterialCategories}
          materialCategories={materialCategories ?? []}
          isEditMode
        />

        <>
          <Button
            type="primary"
            className="btn-primary--dark mt-2.5"
            onClick={showProductCardsModal}
          >
            {t("companies.add-product-card")}
          </Button>

          <AddProductCardModal
            isOpenModal={visibleProductCardModal}
            hideProductCardsModal={hideProductCardsModal}
            adaptedProductCard={adaptedProductCard}
            handleProductCardValue={handleProductCardValue}
            productCardValue={productCardValue}
            clearProductCardValue={clearProductCardValue}
          />
        </>

        {values.product_cards.length ? (
          <ProductCardTable
            productCards={productCards}
            setReorderingIds={setReorderingIds}
            isLoadingProductCards={isLoadingProductCards}
          />
        ) : null}

        <DynamicForm adaptedDimensionUnits={adaptedDimensionUnits} />
      </Modal>
    </>
  );
};

export { EditCompanyModal };
