import React, { FC, useCallback } from "react";
import { getDynamicTrackerFields } from "shared/helpers";
import {
  OrderStepTrackerResponseItem,
  ProductionOutputMaterial,
} from "shared/api";
import { Badge } from "antd";
import { useTranslation } from "react-i18next";
import {
  UpdatedQuantityInput,
  UpdatedStatusInput,
} from "./UpdatedOutputMaterials";
import { ProdNumberInput } from "./ProdNumberInput";
import { ProdTextInput } from "./ProdTextInput";
import { ProdChoicesInput } from "./ProdChoicesInput";

interface OutputMasterCardProps {
  inputIndex: number;
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;

  outputMaterialsValues: {
    output_materials: ProductionOutputMaterial[];
  };
  setOutputMaterialFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => void;
}

const OutputMasterCard: FC<OutputMasterCardProps> = ({
  inputIndex,
  orderStepTrackerResponseItem,
  outputMaterialsValues,
  setOutputMaterialFieldValue,
}) => {
  const { t } = useTranslation("", { keyPrefix: "orders" });

  const memoizedSetFieldValue = useCallback(
    (...args: Parameters<typeof setOutputMaterialFieldValue>) => {
      setOutputMaterialFieldValue(...args);
    },
    [setOutputMaterialFieldValue]
  );

  const getMaterialFields = useCallback(
    (outputMaterials: ProductionOutputMaterial[]) => {
      return {
        unitName: outputMaterials?.[inputIndex]?.unit?.name,

        notDyNamicValues: outputMaterials?.[inputIndex],

        numberOfMaterialItems:
          outputMaterials?.[inputIndex]?.numberOfMaterialItems,

        dynamicTracker: outputMaterials?.[inputIndex]?.dynamicData,
      };
    },
    []
  );

  const { notDyNamicValues, unitName, dynamicTracker } = getMaterialFields(
    outputMaterialsValues?.output_materials
  );

  const dynamicCards = getDynamicTrackerFields(
    orderStepTrackerResponseItem,
    inputIndex
  );

  // const generateDynamicData = useCallback(
  //   (
  //     cards: ConfigDynamicTrackerFields[],
  //     inputIndex: number,
  //     dynamicTracker: DynamicOutputMaterialData[]
  //   ) => {
  //     return (cards ?? []).flatMap((card) => [
  //       {
  //         title: (
  //           <div className="w-full bg-blue-primary text-white my-3">
  //             {card.title}
  //           </div>
  //         ),
  //         data: [],
  //       },
  //       ...(card?.fields ?? [])
  //         .map((field, cardIndex: number) => {
  //           switch (field.type) {
  //             case "integer":
  //             case "float":
  //               return {
  //                 title: field.name,
  //                 data: (
  //                   <ProdNumberInput
  //                     card={card}
  //                     cardIndex={cardIndex}
  //                     dynamicTracker={dynamicTracker}
  //                     field={field}
  //                     inputIndex={inputIndex}
  //                     memoizedSetFieldValue={memoizedSetFieldValue}
  //                     placeholder={
  //                       field?.type === "float"
  //                         ? t("enterFloat")
  //                         : t("enterInteger")
  //                     }
  //                   />
  //                 ),
  //               };
  //             case "text":
  //               return {
  //                 title: field.name,
  //                 data: (
  //                   <ProdTextInput
  //                     card={card}
  //                     cardIndex={cardIndex}
  //                     dynamicTracker={dynamicTracker}
  //                     field={field}
  //                     inputIndex={inputIndex}
  //                     memoizedSetFieldValue={memoizedSetFieldValue}
  //                   />
  //                 ),
  //               };
  //             case "choices":
  //               return {
  //                 title: field.name,
  //                 data: (
  //                   <ProdChoicesInput
  //                     card={card}
  //                     cardIndex={cardIndex}
  //                     dynamicTracker={dynamicTracker}
  //                     field={field}
  //                     inputIndex={inputIndex}
  //                     memoizedSetFieldValue={memoizedSetFieldValue}
  //                   />
  //                 ),
  //               };
  //             default:
  //               // eslint-disable-next-line no-console
  //               console.warn(`Unhandled field type: ${field.type}`);
  //               return undefined;
  //           }
  //         })
  //         .filter((item) => item !== undefined),
  //     ]);
  //   },
  //   []
  // );

  // const dynamicData = generateDynamicData(
  //   dynamicCards,
  //   inputIndex,
  //   dynamicTracker
  // );

  return (
    <Badge.Ribbon text={t("masterCard")} className="!text-[18px]">
      <div className="w-full p-2 bg-blue-100">
        <UpdatedQuantityInput
          notDyNamicValues={notDyNamicValues}
          inputIndex={inputIndex}
          unitName={unitName}
          memoizedSetFieldValue={memoizedSetFieldValue}
        />

        <UpdatedStatusInput
          notDyNamicValues={notDyNamicValues}
          inputIndex={inputIndex}
          memoizedSetFieldValue={memoizedSetFieldValue}
        />

        <div className="flex justify-between">
          {dynamicCards?.map((card, cardIndex) => (
            <div
              key={cardIndex}
              className="w-full card-container my-4 p-4 bg-white shadow-md rounded-md"
            >
              {/* Title objects */}
              <div className="w-full bg-blue-primary text-white my-3 font-bold">
                {card.title}
              </div>

              {/* Objects fields */}
              <div className="card-fields">
                {card.fields.map((field, fieldIndex) => {
                  switch (field.type) {
                    case "integer":
                    case "float":
                      return (
                        <div key={fieldIndex} className="field-item mb-2">
                          <span className="field-name font-medium">
                            {field.description || field.name}
                          </span>
                          <ProdNumberInput
                            card={card}
                            cardIndex={cardIndex}
                            dynamicTracker={dynamicTracker}
                            field={field}
                            inputIndex={inputIndex}
                            memoizedSetFieldValue={memoizedSetFieldValue}
                            placeholder={
                              field?.type === "float"
                                ? t("enterFloat")
                                : t("enterInteger")
                            }
                          />
                        </div>
                      );
                    case "text":
                      return (
                        <div key={fieldIndex} className="field-item mb-2">
                          <span className="field-name font-medium">
                            {field.description || field.name}
                          </span>
                          <ProdTextInput
                            card={card}
                            cardIndex={cardIndex}
                            dynamicTracker={dynamicTracker}
                            field={field}
                            inputIndex={inputIndex}
                            memoizedSetFieldValue={memoizedSetFieldValue}
                          />
                        </div>
                      );
                    case "choices":
                      return (
                        <div key={fieldIndex} className="field-item mb-2">
                          <span className="field-name font-medium">
                            {field.description || field.name}
                          </span>
                          <ProdChoicesInput
                            card={card}
                            cardIndex={cardIndex}
                            dynamicTracker={dynamicTracker}
                            field={field}
                            inputIndex={inputIndex}
                            memoizedSetFieldValue={memoizedSetFieldValue}
                          />
                        </div>
                      );
                    default:
                      return null;
                  }
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Badge.Ribbon>
  );
};

export { OutputMasterCard };
