import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  AllReportsParams,
  CreateReport,
  ExecuteReport,
  ReportItem,
  ReportsResponse,
} from "./models";

export const reportsApi = createApi({
  baseQuery,
  reducerPath: "reportsApi",
  tagTypes: ["reports"],
  endpoints: (builder) => ({
    getReports: builder.query<ReportsResponse, AllReportsParams>({
      query: (params) => ({
        url: "/api/v1/reports/",
        method: "GET",
        params,
      }),
      providesTags: ["reports"],
    }),

    getAllReports: builder.query<ReportItem[], void>({
      query: () => ({
        url: "/api/v1/reports/",
        method: "GET",
      }),
      providesTags: ["reports"],
    }),

    deleteReport: builder.mutation<void, ReportItem>({
      query: (body) => ({
        url: `/api/v1/reports/${body.id}/`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["reports"],
    }),

    updateReport: builder.mutation<void, ReportItem>({
      query: (body) => ({
        url: `/api/v1/reports/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["reports"],
    }),

    createReport: builder.mutation<void, CreateReport>({
      query: (body) => ({
        url: `/api/v1/reports/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["reports"],
    }),

    executeReport: builder.mutation<Blob, ExecuteReport>({
      query: (body) => ({
        url: `/api/v1/reports/${body.id}/execute_report/`,
        method: "POST",
        body,

        responseHandler: (response) => response.blob(),
      }),
      invalidatesTags: ["reports"],
    }),

    getReportData: builder.mutation<void, ExecuteReport>({
      query: (body) => ({
        url: `/api/v1/reports/${body.id}/get_report_data/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["reports"],
    }),
  }),
});

export const {
  useGetReportsQuery,
  useGetAllReportsQuery,
  useDeleteReportMutation,
  useUpdateReportMutation,
  useCreateReportMutation,
  useExecuteReportMutation,
  useGetReportDataMutation,
} = reportsApi;
