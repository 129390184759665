import { useEffect } from "react";
import { notification } from "antd";
import { OrderItem, useLazyGetAllOrdersQuery } from "shared/api";

interface Response {
  data: OrderItem[];
  error: string;
}

export const useOrdersNavigation = () => {
  const [triggerGetAllOrders, { data: orders, error }] =
    useLazyGetAllOrdersQuery<Response>();

  useEffect(() => {
    if (orders?.length === 0) {
      notification.error({
        message: "Error",
        description: "No orders found for this company",
      });
    } else if (orders?.length) {
      const orderCode = orders[0]?.order_code;
      if (orderCode) {
        const url = `/process-step-production/${orderCode}/index-0`;
        window.open(url, "_blank");
      }
    }
  }, [orders]);

  useEffect(() => {
    if (error) {
      notification.error({
        message: "Error",
        description: "Failed to fetch orders. Please try again.",
      });
    }
  }, [error]);

  return {
    handleSearchOrder: (companyId: number) => {
      triggerGetAllOrders({
        buyer: companyId,
        ordering: "-id",
      });
    },
  };
};
