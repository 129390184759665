import { Card, Descriptions } from "antd";
import dayjs from "dayjs";
import React, { FC } from "react";
import { OrderStepTrackerResponseItem } from "shared/api";

interface ProductionGuiNBCProps {
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
}

enum ProductionGuiNBCFields {
  CUSTOMER = "Customer",
  DELIVERY_DATE = "Delivery Date",
  PRODUCT_NAME = "Product Name",
  END_CUSTOMER = "End Customer",
  CITY = "City",
}

const ProductionGuiNBC: FC<ProductionGuiNBCProps> = ({
  orderStepTrackerResponseItem,
}) => {
  const labelStyle = {
    fontWeight: "bold",
    backgroundColor: "#f9fafb",
    padding: "8px",
    borderBottom: "1px solid #e5e7eb",
    color: "#374151",
  };

  const contentStyle = {
    padding: "8px",
    borderBottom: "1px solid #e5e7eb",
    color: "#1f2937",
  };

  const descriptionFields = [
    {
      label: ProductionGuiNBCFields.CUSTOMER,
      value: orderStepTrackerResponseItem?.order.buyer.name,
    },
    {
      label: ProductionGuiNBCFields.DELIVERY_DATE,
      value: dayjs(
        orderStepTrackerResponseItem?.order.wanted_delivery_datetime
      ).format("DD/MM/YYYY HH:mm"),
    },
    {
      label: ProductionGuiNBCFields.PRODUCT_NAME,
      value:
        orderStepTrackerResponseItem?.process_step.dynamic_process_step_fields
          ?.Misc?.product_name_on_label,
    },
    {
      label: ProductionGuiNBCFields.END_CUSTOMER,
      value:
        orderStepTrackerResponseItem?.process_step.dynamic_process_step_fields
          ?.Misc?.end_customer_name,
    },
    {
      label: ProductionGuiNBCFields.CITY,
      value:
        orderStepTrackerResponseItem?.process_step.dynamic_process_step_fields
          ?.Misc?.end_customer_city,
    },
  ];

  return (
    <Card
      title={
        orderStepTrackerResponseItem?.process_step?.process_template?.name?.includes(
          "Rewinding"
        ) ? (
          <div className="text-2xl font-bold text-blue-800">
            Order #{orderStepTrackerResponseItem?.order?.order_code} Information
          </div>
        ) : null
      }
      className="bg-white shadow-md rounded-lg border border-gray-200 p-4"
    >
      <Descriptions
        column={1}
        className="text-gray-700"
        labelStyle={labelStyle}
        contentStyle={contentStyle}
      >
        {descriptionFields?.map((field, index) => (
          <Descriptions.Item key={index} label={field.label}>
            {field.value || "N/A"}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </Card>
  );
};

export { ProductionGuiNBC };
