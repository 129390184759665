import React from "react";
import { Dropdown, Tooltip } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { ReportItem } from "shared/api";

interface ReportsDropdownProps {
  reports: ReportItem[];
  onDownload: (reportId: number) => void;
  noReportsMessage: string;
  popoverString: string;
}

const ReportsDropdown: React.FC<ReportsDropdownProps> = ({
  reports,
  onDownload,
  noReportsMessage,
  popoverString,
}) => (
  <Tooltip title={popoverString} placement="top">
    <Dropdown
      menu={{
        items: reports.length
          ? reports.map((report) => ({
              key: report.id,
              label: (
                <span>
                  <DownloadOutlined className="mr-2 text-blue-500" />
                  {report.name}
                </span>
              ),
              onClick: () => onDownload(report.id),
            }))
          : [
              {
                key: "no-reports",
                label: (
                  <span className="text-gray-400">{noReportsMessage}</span>
                ),
                disabled: true,
              },
            ],
      }}
      trigger={["click"]}
    >
      <img
        src="/exelIcon.png"
        alt="exelIcon"
        style={{ width: 23, height: 24, cursor: "pointer" }}
      />
    </Dropdown>
  </Tooltip>
);

export default ReportsDropdown;
