import { Alert, Input, Modal, Table } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { Colors } from "shared/themes";
import { AddCompanyValues, AdaptedValues } from "shared/types";
import { ColumnsType } from "antd/es/table";
import { useProductCardsKeys } from "shared/hooks";
import Title from "antd/es/typography/Title";

interface AddProductCardModalProps {
  isOpenModal: boolean;
  hideProductCardsModal: () => void;
  adaptedProductCard: AdaptedValues[];
  handleProductCardValue?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  productCardValue?: string;
  clearProductCardValue?: () => void;
}

const AddProductCardModal: FC<AddProductCardModalProps> = ({
  isOpenModal,
  hideProductCardsModal,
  adaptedProductCard,
  handleProductCardValue,
  productCardValue,
  clearProductCardValue,
}) => {
  const { values, setFieldValue } = useFormikContext<AddCompanyValues>();
  const [filteredCards, setFilteredCards] = useState<AdaptedValues[]>([]);

  useEffect(() => {
    if (productCardValue) {
      const filtered = adaptedProductCard.filter((card) =>
        card.label.toLowerCase().includes(productCardValue.toLowerCase())
      );
      setFilteredCards(filtered);
    } else {
      setFilteredCards(adaptedProductCard);
    }
  }, [productCardValue, adaptedProductCard]);

  const closeModal = () => {
    hideProductCardsModal();
    clearProductCardValue?.();
  };

  const { t } = useTranslation();

  const findCardColumns: ColumnsType<AdaptedValues> = [
    {
      title: t("companies.product-card"),
      width: "100%",
      render: (record: AdaptedValues) => record?.label,
    },
  ];

  const selectedCardsColumns: ColumnsType<AdaptedValues> = [
    {
      title: t("companies.product-card"),
      width: "100%",
      render: (record: AdaptedValues) => record?.label,
    },
  ];

  const { rowSelection, addProductCards, selectedProductCards } =
    useProductCardsKeys({
      productCards: values?.product_cards,
      setFieldValue,
      hideProductCardsModal,
      adaptedProductCard,
    });

  return (
    <Modal
      title={t("companies.add-product-card")}
      width="1000px"
      open={isOpenModal}
      centered
      onCancel={closeModal}
      onOk={addProductCards}
      okButtonProps={{
        style: {
          background: `${Colors.blue.primary}`,
          color: "white",
        },
      }}
    >
      <div className="w-full flex gap-x-10 min-h-[600px]">
        <div className="w-1/2 flex flex-col gap-y-2">
          <Input.Search
            placeholder={t("companies.search-product-card")!}
            value={productCardValue}
            onChange={handleProductCardValue}
          />

          <Table
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            columns={findCardColumns}
            dataSource={filteredCards}
            pagination={false}
            className="max-h-[820px] overflow-auto"
          />
        </div>

        <div className="w-1/2 flex flex-col gap-y-2">
          <Title level={5}>{t("companies.selected-product-cards")}</Title>

          {selectedProductCards.length ? (
            <Table
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              columns={selectedCardsColumns}
              dataSource={selectedProductCards}
            />
          ) : (
            <Alert
              type="info"
              showIcon
              message={t("companies.no-product-cards")}
              className="w-full h-14"
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export { AddProductCardModal };
