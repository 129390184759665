import Table from "antd/es/table";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { MaterialsItem, useGetAllMaterialsQuery } from "shared/api";
import { expandMaterials } from "shared/constants";
import {
  useMaterialManagementRequests,
  usePagination,
  useTableSorting,
  useUpdatedMaterialManagementsFilters,
  useUpdatedMaterialManagementTableColumnsGeneration,
} from "shared/hooks";
import { AdaptedValues } from "shared/types";
import { RenderClearFiltersButton } from "shared/ui";

interface UpdatedMaterialManagementTableProps {
  handleMaterialItemToEdit: (materialItem: MaterialsItem) => void;
  materialCategoriesAdapted: AdaptedValues[];
  handelMaterialStockHistoryDetails: (item: MaterialsItem) => void;
  handelMaterialConsumedDetails: (item: MaterialsItem) => void;
  handleMaterialReservationDetails: (item: MaterialsItem) => void;
  handleStockManagementDetails: (item: MaterialsItem) => void;
}

const UpdatedMaterialManagementTable: FC<
  UpdatedMaterialManagementTableProps
> = ({
  handleMaterialItemToEdit,
  materialCategoriesAdapted,
  handelMaterialStockHistoryDetails,
  handelMaterialConsumedDetails,
  handleMaterialReservationDetails,
  handleStockManagementDetails,
}) => {
  const { t } = useTranslation();

  const { offset, limit, pageSize, currentPage, setCurrentPage, setPageSize } =
    usePagination();

  const { ordering, changeSort } = useTableSorting();
  const isSortingByLastConsumed = ordering?.includes("last_consumed");

  const {
    debouncedNameContains,
    handleNamedValue,
    nameValue,
    materialCategoryFilteringItem,
    clearAllFilters,
    isVisibleClearFiltersButton,
    clearNameValue,
    handleMaterialCategory,
  } = useUpdatedMaterialManagementsFilters();

  const {
    data: allMaterials,
    isLoading: isLoadingMaterials,
    isFetching: isFetchingMaterials,
  } = useGetAllMaterialsQuery(
    {
      offset,
      limit,
      expand: expandMaterials,
      ordering,
      name__icontains: debouncedNameContains,
      material_category: materialCategoryFilteringItem,
      last_consumed: isSortingByLastConsumed,
    },
    {}
  );

  const { showDeleteCategoryModal, contextHolder } =
    useMaterialManagementRequests({});

  const columns = useUpdatedMaterialManagementTableColumnsGeneration({
    t,
    handleMaterialItemToEdit,
    changeSort,
    nameValue,
    clearNameValue,
    handleNamedValue,
    materialCategoriesAdapted,
    handleMaterialCategory,
    materialCategoryFilteringItem,
    handelMaterialStockHistoryDetails,
    handelMaterialConsumedDetails,
    handleMaterialReservationDetails,
    handleStockManagementDetails,
    showDeleteCategoryModal,
  });

  return (
    <>
      {contextHolder}

      {isVisibleClearFiltersButton && (
        <RenderClearFiltersButton onClick={clearAllFilters} />
      )}

      <Table
        loading={isLoadingMaterials || isFetchingMaterials}
        columns={columns}
        dataSource={allMaterials?.results}
        rowKey={(obj) => obj.id!}
        pagination={{
          pageSize,
          showSizeChanger: true,
          current: currentPage,
          onShowSizeChange(page, pageSize) {
            setPageSize(pageSize);
            setCurrentPage(page);
          },
          onChange(page) {
            setCurrentPage(page);
          },
          total: allMaterials?.count,
        }}
      />
    </>
  );
};

export { UpdatedMaterialManagementTable };
