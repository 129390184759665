import { Alert, Button, Table } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { MaterialCategoriesItem, useGetMaterialItemsQuery } from "shared/api";
import {
  useDateFilters,
  useDebouncedTableFilter,
  useGenerateItemViewColumns,
  useItemViewMassUpdate,
  useModal,
  usePagination,
  useTableSorting,
} from "shared/hooks";
import { RenderClearFiltersButton } from "shared/ui";
import { MassUpdateModal } from "./MassUpdateModal";

interface ItemViewTableProps {
  selectedMaterialCategoryItem: MaterialCategoriesItem | null;
  globalSerialNumber: string;
  setGlobalSerialNumber: (value: string) => void;
  handleSelectMaterialCategory: (materialCategoryId: number | null) => void;
}

const ItemViewTable: FC<ItemViewTableProps> = ({
  selectedMaterialCategoryItem,
  globalSerialNumber,
  setGlobalSerialNumber,
  handleSelectMaterialCategory,
}) => {
  const { t } = useTranslation();

  const { offset, limit, pageSize, currentPage, setCurrentPage, setPageSize } =
    usePagination();

  const { ordering, changeSort } = useTableSorting();

  const {
    debouncedValue: debouncedSerialNumberValue,
    handleFilterChange: handleSerialNumberValue,
    value: serialNumberValue,
    clearFilterValue: clearSerialNumberValue,
  } = useDebouncedTableFilter("", 400);

  const {
    dateGreater,
    dateLesser,
    clearDates,
    handleDateGreater,
    handleDateLesser,
    isDataFilterActive,
  } = useDateFilters();

  const [selectedStatus, setSelectedStatus] = useState<CheckboxValueType[]>([]);
  const [selectedSituation, setSelectedSituation] = useState<
    CheckboxValueType[]
  >([]);

  const handleStatusChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedStatus(checkedValues);
  };

  const handleSituationChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedSituation(checkedValues);
  };

  const {
    debouncedValue: debouncedMaterialNameValue,
    handleFilterChange: handleMaterialNameValue,
    value: materialNameValue,
    clearFilterValue: clearMaterialNameValue,
  } = useDebouncedTableFilter("", 400);

  const isVisibleClearFiltersButton =
    isDataFilterActive ||
    !!serialNumberValue ||
    !!selectedStatus.length ||
    !!globalSerialNumber ||
    !!selectedMaterialCategoryItem;

  const clearAllFilters = () => {
    clearSerialNumberValue();
    clearDates();
    setSelectedStatus([]);
    clearMaterialNameValue();
    setSelectedSituation([]);
    setGlobalSerialNumber("");
    handleSelectMaterialCategory(null);
  };

  const {
    data: materialItems,
    isLoading: isLoadingMaterialItems,
    isFetching: isFetchingMaterialItems,
  } = useGetMaterialItemsQuery(
    {
      material_stock_batch__material__material_category: globalSerialNumber
        ? undefined
        : Number(selectedMaterialCategoryItem?.id),
      material_stock_batch__material__name__icontains:
        debouncedMaterialNameValue,
      offset,
      limit,
      ordering,
      expand:
        "moved_to_order,material_stock_batch.material,order_production_set.order_process_step_tracker.order",
      serial_number__icontains:
        globalSerialNumber || debouncedSerialNumberValue,
      timestamp__gte: dateGreater ?? undefined,
      timestamp__lte: dateLesser ?? undefined,
      status__in: selectedStatus as string[],
      situation: selectedSituation as string[],
    },
    { skip: !selectedMaterialCategoryItem && !globalSerialNumber }
  );

  const columns = useGenerateItemViewColumns({
    t,
    changeSort,
    // Serial Number Filtering
    handleSerialNumberValue,
    serialNumberValue,
    clearSerialNumberValue,
    // Timestamp filter
    handleDateGreater,
    handleDateLesser,
    isDataFilterActive,
    dynamicFields:
      selectedMaterialCategoryItem?.config_dynamic_tracker_fields ?? [],
    // status filter
    handleStatusChange,
    selectedStatus,
    // material name filter
    handleMaterialNameValue,
    materialNameValue,
    clearMaterialNameValue,
    // situation filter
    handleSituationChange,
    selectedSituation,
  });

  const { selectedKeys, rowSelection } = useItemViewMassUpdate({
    materialItems,
  });

  const [isVisibleMassUpdateModal, showMassUpdateModal, hideMassUpdateModel] =
    useModal();

  if (!selectedMaterialCategoryItem && !globalSerialNumber) {
    return (
      <Alert
        showIcon
        type="info"
        message={t("itemView.selectMaterialCategoryToShowTable")}
        className="!mt-4"
      />
    );
  }

  return (
    <>
      {isVisibleClearFiltersButton && (
        <RenderClearFiltersButton onClick={clearAllFilters} />
      )}

      {selectedKeys.length ? (
        <Button onClick={showMassUpdateModal}>
          {t("itemView.massUpdate")}
        </Button>
      ) : null}

      <Table
        loading={isLoadingMaterialItems || isFetchingMaterialItems}
        columns={columns}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        dataSource={materialItems?.results}
        rowKey={(obj) => obj.id!}
        pagination={{
          pageSize,
          showSizeChanger: true,
          current: currentPage,
          onShowSizeChange(page, pageSize) {
            setPageSize(pageSize);
            setCurrentPage(page);
          },
          onChange(page) {
            setCurrentPage(page);
          },
          total: materialItems?.count,
        }}
        className="!mt-4"
      />

      <MassUpdateModal
        isOpen={isVisibleMassUpdateModal}
        onClose={hideMassUpdateModel}
        selectedIds={selectedKeys}
      />
    </>
  );
};

export { ItemViewTable };
