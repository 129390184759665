import React, { FC, useMemo, useState } from "react";
import { UploadFile } from "antd";
import { useTranslation } from "react-i18next";
import { FormikProvider, useFormik } from "formik";
import { Page } from "shared/ui";
import {
  useCompaniesOptions,
  useDebouncedTableFilter,
  useModal,
} from "shared/hooks";
import { noop } from "shared/helpers";
import {
  CompaniesTable,
  AddCompanyModal,
  EditCompanyModal,
} from "pages/Companies";
import { companiesInitialValues } from "shared/constants";
import { AddCompanySchema, EditCompanySchema } from "shared/schemas";

interface CompaniesProps {}

const Companies: FC<CompaniesProps> = () => {
  const { t } = useTranslation();

  const [visibleAddCompanyModal, showAddCompanyModal, hideAddCompanyModal] =
    useModal();

  const [visibleEditCompanyModal, showEditCompanyModal, hideEditCompanyModal] =
    useModal();

  const [documentList, setDocumentList] = useState<UploadFile<unknown>[]>([]);

  const handleDocumentList = (files: UploadFile[]) => setDocumentList(files);

  const {
    handleFilterChange: handleProductCardValue,
    value: productCardValue,
    clearFilterValue: clearProductCardValue,
  } = useDebouncedTableFilter("", 400);

  const {
    adaptedProductCard,
    adaptedMaterialCategories,
    parentCompanies,
    productCards,
    materialCategories,
    unitsWithLabel,
    isLoadingProductCards,
  } = useCompaniesOptions();

  const validationSchema = useMemo(() => {
    return visibleAddCompanyModal ? AddCompanySchema : EditCompanySchema;
  }, [visibleAddCompanyModal]);

  const addOrUpdateCompanyFormik = useFormik({
    initialValues: companiesInitialValues,
    validationSchema,
    onSubmit: noop,
  });

  return (
    <FormikProvider value={addOrUpdateCompanyFormik}>
      <Page
        title={t("companies.title")}
        buttonTitle={t("companies.add-company-btn")!}
        onClick={showAddCompanyModal}
      >
        <CompaniesTable showEditCompanyModal={showEditCompanyModal} />

        <AddCompanyModal
          isOpenModal={visibleAddCompanyModal}
          adaptedDimensionUnits={unitsWithLabel}
          adaptedProductCard={adaptedProductCard}
          productCards={productCards}
          hideAddCompanyModal={hideAddCompanyModal}
          adaptedMaterialCategories={adaptedMaterialCategories}
          documentList={documentList}
          handleDocumentList={handleDocumentList}
          parentCompanies={parentCompanies}
          materialCategories={materialCategories ?? []}
          isLoadingProductCards={isLoadingProductCards}
          handleProductCardValue={handleProductCardValue}
          productCardValue={productCardValue}
          clearProductCardValue={clearProductCardValue}
        />

        <EditCompanyModal
          isOpenModal={visibleEditCompanyModal}
          adaptedDimensionUnits={unitsWithLabel}
          adaptedProductCard={adaptedProductCard}
          productCards={productCards}
          hideEditCompanyModal={hideEditCompanyModal}
          adaptedMaterialCategories={adaptedMaterialCategories}
          materialCategories={materialCategories}
          documentList={documentList}
          handleDocumentList={handleDocumentList}
          parentCompanies={parentCompanies}
          isLoadingProductCards={isLoadingProductCards}
          handleProductCardValue={handleProductCardValue}
          productCardValue={productCardValue}
          clearProductCardValue={clearProductCardValue}
        />
      </Page>
    </FormikProvider>
  );
};

export { Companies };
