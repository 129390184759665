import { FilterFilled } from "@ant-design/icons";
import { Button } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { TFunction } from "i18next";
import { TableMaterialStockBatch, TableSuppliers } from "pages/Quality";
import { TableMaterialItems } from "pages/Quality/ui/TableMaterialItems";
import {
  QualityOrdering,
  qualityResultsColumn,
  sortDirections,
} from "shared/constants";
import { QualityResultItem, QualityResultResultType } from "shared/api";
import { handleDownloadFile, renderQualityResultStatus } from "shared/helpers";
import {
  DatePickerValue,
  DateRange,
  TableButton,
  TableSearchFilter,
} from "shared/ui";
import classNames from "classnames";

const { name, date, type, batchCode, serialNumber, suppliers, actions } =
  qualityResultsColumn;

interface UseQualityTableColumnsProps {
  t: TFunction;
  openEditQualityResultModal: (record: QualityResultItem) => void;
  changeSort: (ascendOrdering: string, descendOrdering: string) => void;
  nameValue: string;
  handleDateGreater: (date: DatePickerValue, dateString: string) => void;
  handleDateLesser: (date: DatePickerValue, dateString: string) => void;
  batchCodeValue: string;
  clearBatchCodeValue: () => void;
  handleBatchCodeValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clearSerialNumberValue: () => void;
  handleSerialNumberValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  serialNumberValue: string;
  supplierValue: string;
  clearSupplierValue: () => void;
  handleSupplierValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleExportReelLabResult: (report: QualityResultItem) => void;
  handleExportReelLabArchive: (report: QualityResultItem) => Promise<void>;
  showDeleteQualityResultModal: (record: QualityResultItem) => void;
  isDataFilterActive: boolean;
  handleNameValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clearNameValue: () => void;
  handleMaterialGradeValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  materialGradeValue: string;
  clearMaterialGradeValue: () => void;
}

export const useQualityTableColumns = ({
  t,
  openEditQualityResultModal,
  changeSort,
  nameValue,
  handleDateGreater,
  handleDateLesser,
  batchCodeValue,
  clearSerialNumberValue,
  handleSerialNumberValue,
  serialNumberValue,
  clearSupplierValue,
  handleSupplierValue,
  supplierValue,
  handleExportReelLabResult,
  handleExportReelLabArchive,
  showDeleteQualityResultModal,
  isDataFilterActive,
  handleNameValue,
  clearNameValue,
  clearBatchCodeValue,
  handleBatchCodeValue,
  handleMaterialGradeValue,
  materialGradeValue,
  clearMaterialGradeValue,
}: UseQualityTableColumnsProps): ColumnsType<QualityResultItem> => [
  {
    title: t(name.title),
    render: (record: QualityResultItem) => (
      <Button
        onClick={() => openEditQualityResultModal(record)}
        className="border-none hover:border-none bg-transparent shadow-none"
      >
        <span className="underline"> {record?.name}</span>
      </Button>
    ),
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(QualityOrdering.NAME_ASC, QualityOrdering.NAME_DESC);
      },
    }),
    sorter: true,
    sortDirections,
    filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
      <TableSearchFilter
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearNameValue}
        confirm={confirm}
        value={nameValue}
        handleFilterChange={handleNameValue}
        visible={visible}
        placeholder={t("quality.filter-by-name")!}
        title={t("quality.filter-by-name")!}
      />
    ),

    filterIcon: (
      <FilterFilled className={nameValue.length ? "!text-blue-600" : ""} />
    ),
  },
  {
    title: t(date.title),
    render: (record: QualityResultItem) =>
      dayjs.utc(record?.timestamp).format("YYYY-MM-DD"),
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(
          QualityOrdering.TIMESTAMP_ASC,
          QualityOrdering.TIMESTAMP_DESC
        );
      },
    }),
    sorter: true,
    sortDirections,
    filterIcon: (
      <FilterFilled className={isDataFilterActive ? "!text-red-600" : ""} />
    ),
    filterDropdown: () => {
      return (
        <DateRange
          title={t("quality.timestamp")}
          leftPickerTitle={t("quality.timestamp-lte")}
          rightPickerTitle={t("quality.timestamp-gte")}
          handlePickerChangeGreater={handleDateGreater}
          handlePickerChangeLesser={handleDateLesser}
        />
      );
    },
  },
  {
    title: "Material/Grade",
    key: "material_grade",
    render: (record: QualityResultItem) => {
      const materialStockBatchGrade =
        record?.material_stock_batches?.[0]?.material_stock_batch?.material
          ?.name;

      const materialItemGrade =
        record?.material_items?.[0]?.material_item?.material_stock_batch
          ?.material?.name;

      const materialGrade =
        materialStockBatchGrade || materialItemGrade || "N/A";

      return <span>{materialGrade}</span>;
    },
    filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
      <TableSearchFilter
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearMaterialGradeValue}
        confirm={confirm}
        value={materialGradeValue}
        handleFilterChange={handleMaterialGradeValue}
        visible={visible}
        placeholder="Filter by Material/Grade"
        title="Material/Grade"
      />
    ),
    filterIcon: (
      <FilterFilled
        className={materialGradeValue.length ? "!text-green-100" : ""}
      />
    ),
  },
  {
    title: t(type.title),
    render: (record: QualityResultItem) => renderQualityResultStatus(record),
  },
  {
    title: t(batchCode.title),
    render: (record: QualityResultItem) => (
      <TableMaterialStockBatch record={record} />
    ),
    filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
      <TableSearchFilter
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearBatchCodeValue}
        confirm={confirm}
        value={batchCodeValue}
        handleFilterChange={handleBatchCodeValue}
        visible={visible}
        placeholder={t("quality.filter-by-batch-code")!}
        title={t("quality.filter-by-batch-code")!}
      />
    ),

    filterIcon: (
      <FilterFilled
        className={batchCodeValue.length ? "!text-green-100" : ""}
      />
    ),
  },
  {
    title: t(serialNumber.title),
    render: (record: QualityResultItem) => (
      <TableMaterialItems record={record} />
    ),
    filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
      <TableSearchFilter
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearSerialNumberValue}
        confirm={confirm}
        value={serialNumberValue}
        handleFilterChange={handleSerialNumberValue}
        visible={visible}
        placeholder={t("quality.filter-by-serial-number")!}
        title={t("quality.filter-by-serial-number")!}
      />
    ),

    filterIcon: (
      <FilterFilled
        className={serialNumberValue.length ? "!text-orange-100" : ""}
      />
    ),
  },
  {
    title: t(suppliers.title),
    render: (record: QualityResultItem) => (
      <TableSuppliers stockBatches={record?.material_stock_batches} />
    ),
    filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
      <TableSearchFilter
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearSupplierValue}
        confirm={confirm}
        value={supplierValue}
        handleFilterChange={handleSupplierValue}
        visible={visible}
        placeholder={t("quality.filter-by-suppliers")!}
        title={t("quality.filter-by-suppliers")!}
      />
    ),

    filterIcon: (
      <FilterFilled
        className={supplierValue.length ? "!text-purple-200" : ""}
      />
    ),
  },
  {
    title: t(actions.title),
    render: (record: QualityResultItem) => {
      const isReelLabResult =
        record?.result_type === QualityResultResultType.REEL_LAB_RESULT;

      return (
        <div className="flex gap-x-1 item-center justify-center">
          <TableButton
            type="table"
            className={classNames("text-green-400", {
              "opacity-50 pointer-events-none cursor-not-allowed":
                !isReelLabResult,
            })}
            onClick={() => handleExportReelLabResult(record)}
          />

          <TableButton
            type="pdf-file"
            className={classNames("text-blue-400", {
              "opacity-50 pointer-events-none cursor-not-allowed":
                !isReelLabResult,
            })}
            popoverString={t("quality.archive-front-page")!}
            onClick={() => handleExportReelLabArchive(record)}
          />

          <TableButton
            type="file"
            onClick={() => handleDownloadFile(record?.document)}
            popoverString={
              record?.document ? t("quality.download")! : t("quality.empty")!
            }
            className={
              record?.document
                ? "text-black"
                : "opacity-20 pointer-events-none cursor-not-allowed"
            }
          />
          <TableButton
            type="delete"
            onClick={() => showDeleteQualityResultModal(record)}
          />
        </div>
      );
    },
  },
];
