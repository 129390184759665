import {
  DeleteOutlined,
  EditOutlined,
  FieldTimeOutlined,
  SelectOutlined,
  SettingOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  ScheduleOutlined,
  FilePdfOutlined,
  AppstoreAddOutlined,
  TableOutlined,
  CopyOutlined,
  DownloadOutlined,
  SearchOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { Button, Image, Popover, PopoverProps } from "antd";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { icons } from "shared/assets";

interface TableButtonProps {
  type:
    | "edit"
    | "delete"
    | "delete-no-popover"
    | "settings"
    | "reservation"
    | "consumed"
    | "details"
    | "eye"
    | "eye-invisible"
    | "schedule"
    | "pdf-file"
    | "box"
    | "link"
    | "title"
    | "table"
    | "clone"
    | "rIcon"
    | "file"
    | "search"
    | "archive";
  onClick?: () => void;
  itemName?: string;
  className?: string;
  popoverString?: string;
  title?: string;
  popoverPlacement?: PopoverProps["placement"];
  disabled?: boolean;
}

const TableButton: FC<TableButtonProps> = ({
  type,
  onClick,
  itemName,
  className,
  popoverString,
  title,
  popoverPlacement,
  disabled,
}) => {
  const { t } = useTranslation();

  if (type === "edit") {
    return (
      <Popover content={t("table-buttons.edit")}>
        <EditOutlined
          onClick={onClick}
          className={classNames("btn-edit", className)}
          disabled={disabled}
        />
      </Popover>
    );
  }

  if (type === "search") {
    return (
      <Popover content={popoverString || t("table-buttons.search")}>
        <SearchOutlined
          onClick={onClick}
          className={classNames("btn-search", "text-lg", className)}
          disabled={disabled}
        />
      </Popover>
    );
  }

  if (type === "delete") {
    return (
      <Popover content={t("table-buttons.delete")} placement={popoverPlacement}>
        <DeleteOutlined
          onClick={onClick}
          className={classNames("btn-remove z-30", className)}
          disabled={disabled}
        />
      </Popover>
    );
  }

  if (type === "delete-no-popover") {
    return (
      <DeleteOutlined
        onClick={onClick}
        className={classNames("btn-remove z-30", className)}
        disabled={disabled}
      />
    );
  }

  if (type === "settings") {
    return (
      <Popover content={t("material-management.stock-popover")}>
        <SettingOutlined
          onClick={onClick}
          className={classNames("btn-settings", className)}
          disabled={disabled}
        />
      </Popover>
    );
  }

  if (type === "reservation") {
    return (
      <Popover content={t("material-management.reservation-popover")}>
        <SelectOutlined
          onClick={onClick}
          className={classNames("btn-additional", className)}
          disabled={disabled}
        />
      </Popover>
    );
  }

  if (type === "consumed") {
    return (
      <Popover content={t("material-consumed.popover")}>
        <FieldTimeOutlined
          onClick={onClick}
          className={classNames("btn-purple", className)}
          disabled={disabled}
        />
      </Popover>
    );
  }

  if (type === "eye" && !popoverString) {
    return (
      <EyeOutlined
        onClick={onClick}
        className={classNames("btn-settings", className)}
        disabled={disabled}
      />
    );
  }

  if (type === "eye" && popoverString) {
    return (
      <Popover content={popoverString}>
        <EyeOutlined
          onClick={onClick}
          className={classNames("btn-settings", className)}
          disabled={disabled}
        />
      </Popover>
    );
  }

  if (type === "eye-invisible") {
    return (
      <EyeInvisibleOutlined
        onClick={onClick}
        className={classNames(
          "text-gray-400 text-[22.5px] cursor-no-drop",
          className
        )}
        disabled
      />
    );
  }

  if (type === "details") {
    return (
      <Popover content={t("material-category-management.stock-history")}>
        <Button
          type="link"
          className={classNames("!h-auto p-0 !text-blue-primary", className)}
          onClick={onClick}
          disabled={disabled}
        >
          {itemName}
        </Button>
      </Popover>
    );
  }

  if (type === "schedule") {
    return (
      <Popover content={t("planner.planner-view")}>
        <ScheduleOutlined
          onClick={onClick}
          className={classNames("btn-settings", className)}
          disabled={disabled}
        />
      </Popover>
    );
  }

  if (type === "pdf-file") {
    return (
      <Popover content={popoverString || t("packaging.generate-export-label")}>
        <FilePdfOutlined
          onClick={onClick}
          className={classNames("btn-additional", className)}
          disabled={disabled}
        />
      </Popover>
    );
  }

  if (type === "box") {
    return (
      <Popover content={t("packaging.packages")}>
        <AppstoreAddOutlined
          onClick={onClick}
          className={classNames("btn-additional", className)}
          disabled={disabled}
        />
      </Popover>
    );
  }
  if (type === "title") {
    return (
      <Button
        className="border-none hover:border-none bg-transparent shadow-none w-auto"
        onClick={onClick}
      >
        <span className="underline text-[12px]">{title}</span>
      </Button>
    );
  }

  if (type === "table") {
    return (
      <Popover content={t("quality.download")}>
        <TableOutlined
          onClick={onClick}
          className={classNames("btn-additional", className)}
          disabled={disabled}
        />
      </Popover>
    );
  }

  if (type === "clone") {
    return (
      <Popover content={popoverString}>
        <CopyOutlined
          onClick={onClick}
          className={classNames("btn-settings", className)}
          disabled={disabled}
        />
      </Popover>
    );
  }

  if (type === "rIcon") {
    return (
      <Popover content={popoverString}>
        <Image
          preview={false}
          src={icons.rIcon}
          className={classNames("custom-icon", className)}
          onClick={onClick}
        />
      </Popover>
    );
  }

  if (type === "file") {
    return (
      <Popover content={popoverString}>
        <DownloadOutlined
          onClick={onClick}
          className={classNames("btn-additional", className)}
          disabled={disabled}
        />
      </Popover>
    );
  }

  if (type === "archive") {
    return (
      <Popover content={popoverString}>
        <InboxOutlined
          onClick={onClick}
          className={classNames("btn-additional", className)}
          disabled={disabled}
        />
      </Popover>
    );
  }

  return null;
};

export { TableButton };
